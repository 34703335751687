import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import _uniqueId from 'lodash/uniqueId';

import { TableSkeleton, TableHeadCustom, TableEmptyRows, TableNoData, } from '../../../components/table';
import Scrollbar from '../../../components/Scrollbar';
import TableFooter from '../../../components/table/TableFooter';
import Label from '../../../components/label';
import IMTableToolbar from './IMTableToolbar';

import useTable, { emptyRows, getComparator } from '../../../hooks/useTable';
import useLocales from '../../../hooks/useLocales';

import { applySortFilter, numberByLocale } from '../../../utils/misc';

IMStockItemsTable.propTypes = {
    isLoading: PropTypes.bool,
    tableDataList: PropTypes.array.isRequired,
}

const TABLE_HEAD_LIST = [
    { id: 'articleName', label: 'item', sortable: true, show: true, align: 'left' },
    { id: 'articleTypeName', label: 'type', sortable: true, show: true, align: 'left' },
    { id: 'stockMin', label: 'stockMin', sortable: true, show: true, align: 'center' },
    { id: 'quantity', label: 'unitsInStock', sortable: true, show: true, align: 'center' },
    { id: 'totalQuantity', label: 'totalQuantityInStock', sortable: true, show: true, align: 'center' },
    { id: 'stockLevel', label: 'level', sortable: true, show: true, align: 'center' },
];

export default function IMStockItemsTable({ isLoading, tableDataList }) {
    const { translate, currentLang } = useLocales();
    const [searchString, setSearchString] = useState('');
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        selected,
        setPage,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
        onChangeDense,
    } = useTable({ defaultOrderBy: 'articleName' });

    const dataFiltered = applySortFilter({
        tableData: tableDataList,
        comparator: getComparator(order, orderBy),
        searchString,
    });

    const onSearch = (searchString) => {
        setSearchString(searchString);
        setPage(0);
    };

    const formattedData = useMemo(() => dataFiltered.map((row) => ({
        ...row,
        totalQuantity: row.totalQuantity
            ? numberByLocale(row.totalQuantity, currentLang.locale) + row.measurementTypeAbbr
            : String(row.totalQuantity),
    })), [currentLang.locale, dataFiltered]);

    const getColorFromStockLevel = (stockLevel) => {
        switch (stockLevel) {
            case 'green':
                return 'success';
            case 'yellow':
                return 'warning';
            default:
                return 'error';
        }
    };

    const getTextFromStockLevel = (stockLevel) => {
        switch (stockLevel) {
            case 'green':
                return translate('inStock');
            case 'yellow':
                return translate('lowStock');
            default:
                return translate('outOfStock');
        }
    };

    const denseHeight = dense ? 56 : 76;

    return (
        <>
            <IMTableToolbar
                searchString={searchString}
                onSearch={onSearch}
                exportFileTitle={translate('items')}
                headerData={TABLE_HEAD_LIST}
                tableData={formattedData}
            />
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative', marginTop: '10px' }}>
                    {isLoading
                        ? <TableSkeleton />
                        : <Table size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD_LIST}
                                rowCount={tableDataList.length}
                                numSelected={selected.length}
                                onSort={onSort}
                            />
                            <TableBody>
                                {
                                    formattedData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow key={_uniqueId()} hover>
                                                <TableCell align="left">
                                                    {row.articleName}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.articleTypeName}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {numberByLocale(row.stockMin, currentLang.locale)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {numberByLocale(row.quantity, currentLang.locale)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.totalQuantity}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Label
                                                        variant="soft"
                                                        color={getColorFromStockLevel(row.stockLevel)}
                                                    >
                                                        {getTextFromStockLevel(row.stockLevel)}
                                                    </Label>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                }
                                <TableEmptyRows
                                    height={denseHeight}
                                    emptyRows={emptyRows(page, rowsPerPage, tableDataList.length)}
                                />
                                <TableNoData isNotFound={!dataFiltered.length} />
                            </TableBody>
                        </Table>
                    }
                </TableContainer>
            </Scrollbar >
            <TableFooter
                dataFiltered={dataFiltered}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                dense={dense}
                onChangeDense={onChangeDense}
            />
        </>
    )
}