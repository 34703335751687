import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  editedQualityControl: null,
  workersData: [],
  filters: {
    startdate: null,
    enddate: null,
    userid: '',
    productid: '',
    locationid: '',
    search: '',
  },
  error: '',
};

export const fetchHarvestQualityControl = createAsyncThunk(
  'harvestQualityControl/fetchQualityControl',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluation`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchWorkers = createAsyncThunk('harvestQualityControl/fetchWorkers', async (id, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluationworker/getbyevaluation/${id}`);

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

export const fetchQualityControlSingle = createAsyncThunk(
  'harvestQualityControl/fetchQualityControlSingle',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluation/${id}`);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const updateQualityControl = createAsyncThunk(
  'harvestQualityControl/updateQualityControlSingle',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/evaluation`, { ...data });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const deleteHarvestQualityControl = createAsyncThunk(
  'harvestQualityControl/deleteQualityControl',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/evaluation`, {
        data: {
          ids: data.data,
          device: data.device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const deleteWorker = createAsyncThunk(
  'harvestQualityControl/deleteWorker',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/evaluationworker`, {
        data: {
          ids: data.data,
          device: data.device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const harvestQualityControlSlice = createSlice({
  name: 'harvestQualityControl',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        userid: '',
        locationid: '',
        productid: '',
        search: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHarvestQualityControl.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestQualityControl.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
    });
    builder.addCase(fetchHarvestQualityControl.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteHarvestQualityControl.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHarvestQualityControl.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteHarvestQualityControl.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchQualityControlSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchQualityControlSingle.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.editedQualityControl = payload.data;
    });
    builder.addCase(fetchQualityControlSingle.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchWorkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.workersData = payload.data;
    });
    builder.addCase(fetchWorkers.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteWorker.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWorker.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
    });
    builder.addCase(deleteWorker.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = harvestQualityControlSlice.actions;

export default harvestQualityControlSlice.reducer;

export const selectHarvestQualityControlFilters = (state) => state.harvestQualityControl.filters;
export const selectHarvestQualityControlData = (state) => state.harvestQualityControl.data;
export const selectHarvestQualityControlLoading = (state) => state.harvestQualityControl.isLoading;
export const selectHarvestQualityControlError = (state) => state.harvestQualityControl.error;
export const selectHarvestEditedQualityControl = (state) => state.harvestQualityControl.editedQualityControl;
export const selectHarvestQualityControlWorkers = (state) => state.harvestQualityControl.workersData;
