import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import * as XLSX from 'xlsx';
import Iconify from './Iconify';
import useLocales from '../hooks/useLocales';
import { fDateDash } from '../utils/formatTime';
import removeNumberFromString from '../utils/removeNumberFromString';
import { ALPHABET } from '../utils/const';
import transformTitleToSnakeCase from '../utils/transformTitleToSnakeCase';

ExportTable.propTypes = {
    tableData: PropTypes.array.isRequired,
    tableHeaders: PropTypes.array.isRequired,
    exportFileTitle: PropTypes.string.isRequired,
    tableUpperHeaders: PropTypes.array,
}

export default function ExportTable({ tableData, tableHeaders, tableUpperHeaders, exportFileTitle }) {
    const { translate } = useLocales();

    const handleSave = () => {
        const headers = tableHeaders.filter(header => header.show).map(header => header.id);
        const formattedTableData = formatTableData(tableData, headers);

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(
            formattedTableData,
            { origin: tableUpperHeaders ? 'A2' : 'A1', header: headers },
        );

        const keys = Object.keys(ws);
        tableHeaders.filter(header => header.show).forEach((header, index) => {
            if (!header?.label) return;
            ws[`${tableHeaders.length > 50
                ? removeNumberFromString(keys[index])
                : ALPHABET[index]}${tableUpperHeaders ? '2' : '1'}`].v = translate(header.label);
        });

        if (tableUpperHeaders) {
            let col = 0;
            tableUpperHeaders.forEach((header) => {
                if (header.label) {
                    ws[`${removeNumberFromString(keys[col])}1`] = { v: translate(header.label), t: 's' };
                };

                col += header.cols;
            });
        };

        XLSX.utils.book_append_sheet(wb, ws, exportFileTitle);
        XLSX.writeFile(wb, `${transformTitleToSnakeCase(exportFileTitle)}_${fDateDash(new Date())}.xlsx`);
    };

    return (
        <IconButton onClick={() => handleSave()} sx={{ justifyContent: 'flex-end' }}>
            <Iconify icon={'file-icons:microsoft-excel'} width={20} height={20} />
        </IconButton>
    );
}

const FORMAT_NUMBER_FIELDS_EXCEPTIONS = [
    'phone',
    'socialNumber',
    'fiscalNumber',
    'idNumber',
];

const formatTableData = (tableData, headers) => {
    const formatted = [];

    tableData.forEach((row, index) => {
        const updatedRow = {};

        Object.keys(row).forEach((key) => {
            if (!headers.includes(key)) return;
            if (
                +tableData[index][key] > 0 &&
                !FORMAT_NUMBER_FIELDS_EXCEPTIONS.includes(key)
            ) {
                updatedRow[key] = Math.round(+tableData[index][key] * 100) / 100;
                return;
            }
            updatedRow[key] = tableData[index][key] || null;
        });
        formatted.push(updatedRow);
    });

    return formatted;
};
