// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  harvest_entries: 'Registros de Cosecha',
  start_date: 'Inicio',
  end_date: 'Fin',
  search: 'Buscar',
  filters: 'Filtros',
  dashboard: 'Panel de control',
  harvest: 'Cosecha',
  entries: 'Registros Cosecha',
  scrap: 'Rechazo',
  qualityControl: 'Control Calidad',
  massMovement: 'Movimiento Masa',
  harvestVsWaste: 'Cosecha vs Rechazo',
  productivity: 'Productividad Cojedor',
  packaging: 'Envase',
  packagingSum: 'Productividad',
  workMaps: 'Mapas Trabajo',
  checklistReport: 'Informe del Checklist',
  workHours: 'Horas Trabajo',
  fertirrigation: 'Fertirrigacíon',
  tanksList: 'Lista Tanques',
  tanksHistory: 'Informe de Regarga',
  humanResources: 'Recursos Humanos',
  staff: 'Trabajadores',
  staffDocuments: 'Documentos de Trabajadores',
  workingNow: 'Trabajando ahora',
  inventoryManagement: 'Inventario',
  itemTypes: 'Tipos de artículos',
  items: 'Artículos',
  stocks: 'Almacenes',
  stocksMovements: 'Movimentos de Stock',
  stockByDay: 'Stock por Fecha',
  consumption: 'Consumos',
  electricity: 'Eletricidad',
  water: 'Agua',
  fuel: 'Combustible',
  parameters: 'Parámetros',
  bonus: 'Bono Cogedor',
  locations: 'Sectores',
  culture: 'Cultivo',
  absenceTypes: 'Tipos de Ausencia',
  harvestDefectTypes: 'Control Calidad Cosecha',
  performanceTypes: 'Parámetros de comportamiento del trabajador',
  documentTypes: 'Documentos',
  reportScheduler: 'Programador Informes',
  day: 'Fecha',
  worker_id: 'ID',
  name: 'Nombre',
  location: 'Sector',
  yield: 'Contenedor',
  quantity: 'Ctd',
  hour: 'Hora',
  weight_kg: 'Peso (Kg)',
  weight: 'Peso',
  provider: 'Empresa',
  supervisor: 'Supervisor',
  no_data: 'Sin datos',
  edit: 'Editar',
  delete: 'Eliminar',
  dense: 'Compacto',
  rows_per_page: 'Líneas por página',
  clear_all: 'Limpiar todo',
  registered_by: 'Registrado por',
  workers: 'Trabajadores',
  providers: 'Empresas',
  supervisors: 'Supervisores',
  cancel: 'Cancelar',
  harvest_entries_edit: 'Editar entrada Cosecha',
  harvest_scrap_edit: 'Editar entrada de rechazo',
  harvest_quality_control_edit: 'Editar entrada de Control de Calidad',
  crop: 'Cosecha',
  waste: 'Rechazo',
  industrial_waste: 'Industrial',
  harvest_scrap: 'Residuo de Cosecha',
  harvest_type: 'Cultivo',
  harvest_scrap_create: 'Registrar Residuo',
  create: 'Crear',
  update_successful: 'Actualización con éxito',
  successfully_created: 'Creado con éxito',
  harvest_vs_waste: 'Cosecha vs Residuo',
  waste_percent: 'Residuo %',
  industrial: 'Industrial',
  industrial_percent: 'Industrial %',
  total_waste: 'Total Residuo',
  total_waste_percent: 'Total Residuo %',
  harvest_mass_movement: 'Movimiento de masas',
  harvest_productivity: 'Productividad del Cojedor',
  time_harvest: 'Trabajado (h)',
  speed: 'Velocidad (Kg/h)',
  speed_kg_h: 'Velocidad (Kg/h)',
  companies: 'Empresas',
  company: 'Empresa',
  package: 'Contenedor',
  worker: 'Trabajador',
  harvest_packaging: 'Contenedor de Cosecha',
  start: 'Inicio',
  end: 'Fin',
  rest_time_min: 'Descanso (min)',
  hours: 'Horas',
  language: 'Idioma',
  harvest_quality_control: 'Control de calidad de Cosecha',
  defect: 'Defecto',
  bonus_multiplier: 'Multiplicador de Bono',
  done_by: 'Por',
  notes: 'Notas',
  working_now: 'Trabajando ahora',
  document_id: 'Id. del documento',
  tax_id: 'DNI',
  social_security: 'Seguridad Social',
  nationality: 'Nacionalidad',
  birth_date: 'Fecha de nacimiento',
  role: 'Cargo',
  male: 'Hombre',
  female: 'Mujer',
  other: 'Otro',
  gender: 'Género',
  address: 'Dirección',
  phone: 'Teléfono',
  email: 'Email',
  work_type: 'Tipo de Trabajo',
  unclosed: 'Por cerrar',
  work_maps: 'Mapas de Trabajo',
  work_maps_edit: 'Editar Mapa de Trabajo',
  work_hours_edit: 'Editar Horas de Trabajo',
  work_hours: 'Tiempo de trabajo',
  map_hours_edit: 'Editar Mapa de Trabajo',
  map_time_in: 'Hora inicio',
  map_time_out: 'Hora fin',
  map_time_rest: 'Pausa (min)',
  checklist_report: 'Informe del Checklist',
  settings: 'Ajustes',
  home: 'Inicio',
  profile: 'Perfil',
  logout: 'Cerrar Sesión',
  light: 'Claro',
  dark: 'Obscuro',
  lowCondition: '{{fieldName}} bajo ({{value}})',
  highCondition: '{{fieldName}} alto ({{value}})',
  emptyCondiditon: 'Valor de {{fieldName}} no es válido',
  drainageRaisedTo: 'Drenaje subido al ({{value}})',
  drainageLoweredTo: 'Drenaje bajó al ({{value}})',
  tankAlert: 'Tanque sin recarga por ({{value}}) dias',
  EC: 'EC',
  pH: 'pH',
  percentOfDrainage: '% de drenaje',
  percentOfHumidity: '% de humedad',
  temperature: 'Temperatura',
  ECInAndECOut: 'EC in + EC out',
  readingOneHour: '1ª Lectura',
  readingTwoHour: '2ª Lectura',
  readingThreeHour: '3ª Lectura',
  readingOnePercent: '% 1ª Lectura',
  readingTwoPercent: '% 2ª Lectura',
  readingThreePercent: '% 3ª Lectura',
  dailyPercent: 'Media Diaria',
  list: 'Lecturas',
  cumulative: 'Acumulado',
  drainage: 'Drenaje',
  valve: 'Válvula',
  inPH: '[In] pH',
  outPH: '[Out] pH',
  inEC: '[In] EC',
  outEC: '[Out] EC',
  inVolume: '[In] Volumen',
  inTotalVolume: '[In] Total Volumen',
  outVolume: '[Out] Volume',
  percentage: 'Drenaje %',
  updatedAt: 'Última actualización',
  updatedBy: 'Actualizado por',
  createdAt: 'Creación',
  action: 'Acciones',
  fertirrigationDrainage: 'Drenaje Fertirrigación',
  message: 'Alerta',
  solved: 'Resuelto',
  yes: 'Sí',
  no: 'No',
  substrate: 'Sustrato',
  humidityPercent: 'Humedad %',
  ECmilliSiemensPerCentimeter: 'EC mS/cm',
  humiditySensorial: 'Humedad Sensorial',
  roots: 'Raíces',
  tanks: 'Tanque',
  view: 'Ver',
  itemType: 'Tipo',
  tank: 'Cuba',
  totalVolume: 'Capacidad',
  stock: 'Almacén Principal',
  refillHistory: 'Registro de llenado',
  doneBy: 'Registrado por',
  refillItems: 'Artículos de recarga',
  additionalStock: 'Almacén Secundario',
  totalItems: 'Artículos',
  date: 'Fecha',
  volume: 'Volumen',
  resolutionDay: 'Resolución Día',
  reading1: '1ª Lectura',
  reading2: '2ª Lectura',
  reading3: '3ª Lectura',
  percent: 'Porcentaje',
  alerts: 'Alertas',
  information: 'Información',
  back: 'Volver',
  absenceBook: 'Libro de Ausencias',
  absenceBookEdit: 'Editar Ausencia',
  absenceType: 'Tipo de Ausencia',
  durationInDays: 'Duración (días)',
  all: 'Todos',
  fieldIsRequired: 'Campo obligatorio',
  workerFeedback: 'Feedback trabajador',
  timesheets: 'Registro de horas',
  payroll: 'Nómina',
  absenceBookCreate: 'Crear ausencia',
  timeOther: 'Tiempo Otros',
  timeHarvest: 'Tiempo Cosecha',
  kg: 'Kg',
  kgHour: 'Kg/h',
  multiplier: 'Multiplicador',
  showTotals: 'Mostrar Totales',
  behavior: 'Comportamiento',
  behaviorType: 'Tipo de comportamiento',
  workerFeedbackCreate: 'Registrar Feedback de Trabajador',
  workerFeedbackEdit: 'Editar Feedback de Trabajador',
  theValueShouldBeANumber: 'Debe ser un número',
  taxID: 'Identificación Fiscal',
  idNumber: 'Número de identificación',
  socialSecurityNumber: 'Seguridad Social',
  typeOfContract: 'Tipo de contrato',
  salary: 'Salario',
  staffEdit: 'Editar datos del trabajador',
  staffCreate: 'Crear Trabajador',
  general: 'Informacion General',
  legalInfo: 'Información legal',
  contract: 'Datos del Contrato',
  contractType: 'Tipo de contrato',
  id: 'ID',
  active: 'Activo',
  editInfo: 'Datos del Registro',
  activateStaff: 'Activar Trabajador(es)',
  inactivateStaff: 'Desactivar Trabajador(es)',
  activateSingleStaffMember: '¿Activar trabajador?',
  activateMultipleStaffMembers: '¿Activar trabajador?',
  inactivateSingleStaffMember: '¿Desactivar trabajador?',
  inactivateMultipleStaffMembers: '¿Desactivar trabajadores?',
  activate: 'Activar',
  inactivate: 'Desactivar',
  changeStaffActiveStatusSuccess: 'Estado de {{numberOfWorkers}} trabajador(es) actualizado',
  documentType: 'Tipo de documento',
  expiryDate: 'Expira en',
  filename: 'Nombre de archivo',
  editDocument: 'Editar documento',
  addDocument: 'Añadir documento',
  link: 'Enlace',
  invalidLinkFormat: 'Enlace con formato no válido',
  upload: 'Subir',
  download: 'Descargar',
  activityTimeReport: 'Informe  Actividades',
  missingMandatoryDocs: 'Faltan documentos obligatorios',
  expiresInDays: 'Expira en',
  workType: 'Tipo de trabajo',
  total: 'Total',
  field: 'Finca',
  low: 'Bajo',
  high: 'Alto',
  normal: 'Normal',
  consumptionElectricityCreate: 'Registrar Lectura de Electricidad',
  consumptionElectricityEdit: 'Editar Lectura de Electricidad',
  consumptionWaterEdit: 'Editar Lectura de Agua',
  consumptionWaterCreate: 'Registrar Lectura de Agua',
  fileIsNotFound: 'Fichero no encontrado',
  reading: 'Lectura',
  fuelType: 'Tipo de combustible',
  consumptionFuelEdit: 'Editar Lectura de combustible',
  consumptionFuelCreate: 'Registrar Lectura de combustible',
  documents: 'Documentos',
  fileTooBig: 'Fichero muy grande. ¡Tamaño máximo 10MB',
  harvestMassMovement: 'Movimiento de Masa de Cosecha',
  absenceParameters: 'Tipos de Ausencia',
  pickingBonus: 'Bono de Cosecha',
  abbreviation: 'Sigla',
  editAbsenceParameters: 'Editar Tipo de Ausencia',
  createAbsenceParameters: 'Crear Tipo de Ausencia',
  description: 'Descripción',
  type: 'Clase',
  bonusMultiplier: 'Multiplicador de Bonus',
  positive: 'Positivo',
  negative: 'Negativo',
  createWorkerFeedbackParameters: 'Crear Tipo de Feedback',
  editWorkerFeedbackParameters: 'Editar Tipo de Feedback',
  fromKgHour: 'Desde (Kg/h)',
  toKgHour: 'Hasta (Kg/h)',
  bonusDollars: 'Bonus ($)',
  createPickingBonusParameters: 'Crear Parámetro de Bonus de Cosecha',
  editPickingBonusParameters: 'Editar Parámetro de Bonus de Cosecha',
  deleteDocumentTypesParameters: 'Eliminar parámetro de clase de documento',
  createDocumentTypesParameters: 'Crear Tipo de documento',
  editDocumentTypesParameters: 'Editar Tipo de documento',
  document: 'Documento',
  mandatory: 'Obligatorio',
  expiration: 'Caducidad',
  farm: 'Información Agrícola',
  cropContainers: 'Enpaque',
  cropQualityControl: 'Control de calidad de Cosecha',
  createCropParameters: 'Crear Cultivo',
  harvestBonusPenalty: 'Penalización de Bono (Multiplicador)',
  cropQualityControlParameters: 'Control de Calidad de Cosecha',
  createCropQualityControlParameters: 'Crear tipo de Defecto',
  editCropQualityControlParameters: 'Editar tipo de Defecto',
  downloadLimitReached: 'Límite de descarga alcanzado por favor seleccione menos de {{limit}} archivos',
  linkIsTooLong: 'La extensión máxima es de 100 caracteres',
  deleteCropContainersParameters: 'Eliminar Envase',
  containerName: 'Nombre de Envase',
  capacityGr: 'Capacidad (gr)',
  editCropContainersParameters: 'Editar Envase',
  createCropContainersParameters: 'Crear Envase',
  createLocationsParameters: 'Crear Localización',
  editLocationsParameters: 'Editar Localización',
  irrigatorsPerVase: 'Irrigadores/Vaso',
  valveSubsector: 'Válvula/Subsector',
  sector: 'Sector',
  locationsParameters: 'Parámetros de localización',
  cropContainersParameters: 'Parámetros del envase',
  reporting: 'Informes',
  reportName: 'Nombre del informe',
  frequency: 'Frecuencia',
  schedule: 'Horario',
  sendTo: 'Enviar a',
  ccTo: 'CC',
  createReportingParameters: 'Crear Programación de informe',
  editReportingParameters: 'Editar Programación de informe',
  cropParameters: 'Parámetro de Cosecha',
  stockName: 'Almacén',
  inventoryStocks: 'Existencias',
  stockMovements: 'Movimiento de Stock',
  stockCreate: 'Crear Almacén',
  stockMin: 'Nível de Stock minimo',
  unitsInStock: 'Unidades en Stock',
  totalQuantityInStock: 'Ctd. en Stock',
  level: 'Nivel de Stock',
  item: 'Artículo',
  operation: 'Operación',
  sourceStock: 'Desde',
  destinyStock: 'Hasta',
  affectedQuantity: 'Ctd. Operada',
  initialQuantity: 'Ctd. Incial',
  finalQuantity: 'Ctd. Final',
  recentMovements: 'Operaciónes Recientes',
  operationTypes: 'Tipos de operación',
  itemTypesList: 'Tipos de artículos',
  stocksList: 'Lista de Almacenes',
  itemsList: 'Lista de Artículos',
  unitType: 'Clase de unidad',
  unitQuantity: 'Capacidad del Envase',
  unitInStock: 'Unidades en stock',
  quantityInStock: 'Disponible',
  inventoryItems: 'Artículos de almacén',
  itemCreate: 'Crear Artículo',
  itemEdit: 'Editar Artículo',
  itemTypeCreate: 'Crear Artículo',
  itemTypeEdit: 'Editar Tipos de Artículos',
  inventoryTypes: 'Tipos de Artículos',
  access: 'Acceso',
  manageAccess: 'Acceso App',
  addAccess: 'Añadir acceso App',
  password: 'Contraseña',
  userName: 'Nombre de usuario',
  onboardingLanguage: 'Idioma de registro',
  portal: 'Portal',
  app: 'App',
  both: 'Ambos',
  portuguese: 'Portugués',
  english: 'Inglés',
  spanish: 'Español',
  passwordReset: 'Cambiar contraseña',
  usernameIsTooLong: 'Máximo 100 caracteres ',
  usernameIsTooShort: 'Mínimo 8 caracteres ',
  passwordIsTooLong: 'Máximo 100 caracteres ',
  passwordIsTooShort: 'Mínimo 6 caracteres ',
  invalidPasswordFormat: 'Formato de contraseña no válido',
  deleteAccess: 'Eliminar acceso',
  resetPassword: 'Restablecer contraseña',
  deleteAccessText: '¿Eliminación acceso al trabajador?',
  viewStock: 'Ver Almacén',
  removeStockItemsBeforeDeletion: 'El almacén debe estar vacío antes de eliminarlo',
  totalHarvest: 'Total Cosecha',
  totalPackages: 'Total Envases',
  productionSpeed: 'Velocidad de Cocecha',
  pickingPerformance: 'Productividad de cosecha',
  industrialWaste: 'Industrial',
  pickedInKg: 'Cocecha (Kg)',
  workedInHours: 'Trabajado (h)',
  speedInKgHour: 'Velocidad (Kg/h)',
  previousDay: 'vs día anterior',
  vsPreviousDay: 'vs día anterior',
  harvestSlashWaste: 'Cosecha / Rechazo',
  drainageAlert: 'Alertas Fertirigacion',
  notSolved: 'No resuelto',
  at: 'a las',
  timeSpentOnTasks: 'Tiempo gastado en tareas',
  totalOfHoursSpentOnEachTask: 'Total gastado en cada tarea',
  unclosedWorkMaps: 'Mapas no cerrados',
  workMapsNeedClosing: '{{workMapsQuantity}} Mapas de trabajo que necesitan cierre',
  viewAll: 'Ver todos',
  allMapsAreClosedForThisDay: 'Todos los mapas para este día han sido cerrados',
  accessManagement: 'Gestión Accesos',
  portalPermissions: 'Permisos',
  roleList: 'Cargo',
  moduleList: 'Módulo',
  canView: 'Puede ver',
  canEdit: 'Puede editar',
  canCreate: 'Puede crear',
  canDelete: 'Puede eliminar',
  menu: 'Menú',
  operations: 'Operaciones',
  changesAreNotSaved: 'Todos los cambios se perderán. ¿Desea continuar?',
  passwordHint: 'Un mínimo de seis caracteres, al menos una letra y un número',
  logins: 'Acceso',
  loginEdit: 'Editar Acceso',
  loginCreate: 'Crear Acceso',
  signInToFarmUpPortal: 'Sign in to FarmUp Portal',
  university: 'Universidad',
  website: 'Website',
  whatsApp: 'WhatsApp',
  phoneContact: 'Teléfono',
  enterYourDetailsBelow: 'Introduzca sus datos a continuación',
  login: 'Login',
  loginIsRequired: 'Inicio de sesión obligatorio',
  passwordIsRequired: 'Se requiere contraseña',
  incorrectCredentials: 'Credenciales incorrectas',
  deletePopupTitle: 'Eliminar',
  deletePopupMessageOneItem: '¿Confirma que desea eliminar este registro?',
  deletePopupMessageMultipleItems: '¿Confirma que desea eliminar estos registros?',
  banned: 'Inactivo',
  selectDateRange: 'Seleccionar intervalo de fechas',
  endDateMustBeLaterThanStartDate: 'La fecha de finalización debe ser posterior a la de inicio',
  maximumRangeCannotBeMoreThanOneYear: 'La duración máxima no puede ser superior a un año',
  inactive: 'Inactivo',
  status: 'Estado',
  sorryPageNotFound: 'Lo sentimos, ¡página no encontrada!',
  pageNotFound: '404 Página no encontrada',
  couldNotFindThePageYouAreLookingFor: 'Lo sentimos, no hemos podido encontrar la página que buscas. ¿Quizá ha escrito mal la URL? Asegúrate de revisar tu ortografía.',
  goToHome: 'Volver a la página de inicio',
  showInactiveWorkers: 'Mostrar trabajadores inactivos',
  withAccess: 'Con acceso',
  inactiveUsers: 'Inactivos',
  searchInHelp: 'Buscar em ayuda...',
  everyMonth: 'cada mes',
  everyMonthDaysShort: 'día del mes',
  everyWeekDaysShort: 'día de la semana',
  everyHours: 'cada hora',
  everyMinutes: 'cada minuto',
  everyMinutesForHourPeriod: 'cada',
  yearOption: 'año',
  monthOption: 'mes',
  weekOption: 'semana',
  dayOption: 'día',
  hourOption: 'hora',
  minuteOption: 'minuto',
  rebootOption: 'reiniciar',
  prefixPeriod: 'Cada',
  prefixMonths: 'en',
  prefixMonthDays: 'en',
  prefixWeekDays: 'en',
  prefixWeekDaysForMonthAndYearPeriod: 'y',
  prefixHours: 'en',
  prefixMinutesForHourPeriod: 'en',
  suffixMinutesForHourPeriod: 'minuto(s)',
  sunday: 'Domingo',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  january: 'Enero',
  february: 'Febrero',
  march: 'Marzo',
  april: 'Abril',
  may: 'Mayo',
  june: 'Junio',
  july: 'Julio',
  august: 'Agosto',
  september: 'Septiembre',
  october: 'Octubre',
  november: 'Noviembre',
  december: 'Diciembre',
  sundayShort: 'DOM',
  mondayShort: 'LUN',
  tuesdayShort: 'MAR',
  wednesdayShort: 'MIÉ',
  thursdayShort: 'JUE',
  fridayShort: 'VIE',
  saturdayShort: 'SAB',
  januaryShort: 'ENE',
  februaryShort: 'FEB',
  marchShort: 'MAR',
  aprilShort: 'ABR',
  mayShort: 'MAY',
  juneShort: 'JUN',
  julyShort: 'JUL',
  augustShort: 'AGO',
  septemberShort: 'SEP',
  octoberShort: 'OCT',
  novemberShort: 'NOV',
  decemberShort: 'DIC',
  recordsWithTheSameWorkerIdAreNotAllowed: 'No se permiten registros con el mismo ID de trabajador',
  recordsWithTheSameFiscalNumberAreNotAllowed: 'No se admiten registros con el mismo número fiscal',
  recordsWithTheSameFilenameAreNotAllowed: 'No se admiten registros con el mismo nombre de archivo',
  recordsWithTheSameNameAreNotAllowed: 'No se admiten registros con el mismo nombre',
  expirationDateIsRequiredForThisTypeOfDocument: 'La fecha de caducidad es obligatoria para este tipo de documento',
  somethingWentWrongWhileCreatingStaffMember: 'Algo ha ido mal al crear el trabajador',
  recordsWithTheSameGroupAreNotAllowed: 'No se permiten registros con el mismo grupo',
  workerHasAlreadyTheAccess: 'El trabajador ya tiene acceso',
  recordsWithTheSameUsernameAreNotAllowed: 'No se permiten registros con el mismo nombre de usuario',
  somethingWentWrongWhileFetchingTheRequest: 'Algo ha ido mal durante la obtención de la solicitud',
  outOfStock: 'Agotado',
  lowStock: 'Bajo Stock',
  inStock: 'En stock',
  apply: 'Aplicar',
  editCropParameters: 'Editar parámetros de cultivo',
  formulation: 'Formulación',
  uploadFiles: 'Subir Fichero',
  maximumOneFileToUpload: 'Maximum one file to upload',
  downloadTemplate: 'Descargar template',
  ok: 'OK',
  numberOfWorkersImportedSuccessfully: '{{numberOfWorkers}} trabajadores importados con éxito!',
  numberOfWorkersWereInsertedSuccessfully: '{{numberOfWorkers}} los trabajadores se han insertado correctamente!',
  wrong_workerid: 'Falta el ID del trabajador o contiene caracteres no numéricos',
  already_exists: 'El ID de trabajador ya existe',
  missing_field_name: 'Falta el nombre del trabajador',
  missing_field_company: 'Falta la empresa',
  missing_field_role: 'Role is missing',
  wrong_company: 'Empresa no encontrada',
  wrong_role: 'Función no encontrada',
  wrong_social_security: 'La Seguridad Social debe constar de 11 caracteres numéricos',
  wrong_birth_date: 'El formato de la fecha de nacimiento debe ser DD.MM.AAAA',
  wrong_contract_date: 'El formato de la fecha de inicio del contrato debe ser DD.MM.AAAA',
  wrong_salary: 'El salario debe constar únicamente de caracteres numéricos',
  lines: 'Líneas',
  members: 'Trabajadores',
  types: 'Tipos',
  editCompany: 'Editar empresa',
  createCompany: 'Crear empresa',
  harvestKg: 'Cosecha (Kg)',
  save: 'Guardar',
  selected: 'seleccionados',
  workerHours: 'Horas Trabajador',
  mapHours: 'Horas Mapa de trabalho',
  workerId: 'ID Trabajador',
  permissions: 'Permisos',
  valueMustBeAnInteger: 'El valor debe ser un número entero',
  workTypes: 'Tipos de trabajo',
  workTypesParameters: 'Parámetros de los tipos de trabajo',
  editWorkTypesParameters: 'Editar Tipo de Trabajo',
  createWorkTypesParameters: 'Crear Tipo de Trabajo',
  daysOffPlanning: 'Planificador Descanso',
  monthPicker: 'Mes',
  thisGapShouldBeChangedInTheAbsenceBookSection: 'Este dia de descanso debe anotarse en el Libro de Ausencias',
  dropOrSelectFile: 'Soltar o seleccionar archivo',
  dropFilesHereOrClickBrowseThroughYourMachine: 'Suelte los archivos aquí o haga clic en <1>navegar</1> a través de su máquina',
  harvestPackagingSummary: 'Resumen de Envase',
  without_errors: 'Sin errores',
  pleaseFixErrorsAndReuploadFile: 'Por favor, corrija los errores y vuelva a cargar el archivo',
  continue: 'Continuar',
  wrongUsernameOrPassword: 'Login o contraseña incorrectos',
  accountHasNoAccessToTheApplication: 'Su cuenta no tiene permisos para utilizar esta aplicación',
  details: 'Detalles',
  stockEdit: 'Editar Almacén',
  harvestProductivityListTab: 'Por Sector',
  harvestProductivitySummaryTab: 'Sumario',
  harvestProductivityPickerBonusTooltip: '¿Cómo se calcula? ( Bonificación mapa 1 + Bonificación mapa 2 + .... ) x Multiplicador',
  reportDescription: 'Descripción',
  sendNow: 'Enviar ahora',
  reportRequestSent: 'Solicitud de informe enviada, los resultados llegarán pronto a su correo electrónico configurado',
  wrongDateFormat: 'Formato de fecha incorrecto',
};

export default es;
