import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    parametersPickingBonus: [],
    parametersPickingBonusById: {},
    error: '',
};

export const fetchParametersPickingBonus = createAsyncThunk(
    'parametersPickingBonus/fetchParametersPickingBonus',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/bonus`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersPickingBonus = createAsyncThunk(
    'parametersPickingBonus/deleteParametersPickingBonus',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/bonus`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersPickingBonus = createAsyncThunk(
    'parametersPickingBonus/createParametersPickingBonus',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/bonus`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersPickingBonusById = createAsyncThunk(
    'parametersPickingBonus/fetchParametersPickingBonusById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/bonus/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersPickingBonus = createAsyncThunk(
    'parametersPickingBonus/updateParametersPickingBonus',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/bonus`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersPickingBonusSlice = createSlice({
    name: 'parametersPickingBonusSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersPickingBonus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersPickingBonus.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.parametersPickingBonus = payload.data;
        });
        builder.addCase(fetchParametersPickingBonus.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersPickingBonus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersPickingBonus.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersPickingBonus.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersPickingBonus.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersPickingBonus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersPickingBonus.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersPickingBonusById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.parametersPickingBonusById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersPickingBonusById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersPickingBonusById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersPickingBonus.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersPickingBonus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersPickingBonus.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersPickingBonusSlice.reducer;

export const selectParametersPickingBonusData = (state) => state.parametersPickingBonus.parametersPickingBonus;
export const selectParametersPickingBonusById = (state) => state.parametersPickingBonus.parametersPickingBonusById;
export const selectParametersPickingBonusLoading = (state) => state.parametersPickingBonus.isLoading;
export const selectParametersPickingBonusError = (state) => state.parametersPickingBonus.error;
