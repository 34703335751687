import React from 'react';
import PropTypes from 'prop-types';
import _uniqueid from 'lodash/uniqueId';
import { Paper, alpha, Box, Typography, Button, Stack, useTheme } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

export const WORKERS_UPLOAD_STATUS = {
    OK: 'OK',
    NOK: 'NOK',
};

HRStaffUploadWorkersResponseMessage.propTypes = {
    response: PropTypes.shape({
        status: PropTypes.oneOf(Object.values(WORKERS_UPLOAD_STATUS)),
        inserted: PropTypes.number,
        errors: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            lines: PropTypes.arrayOf(PropTypes.number),
        }))
    }),
    onClose: PropTypes.func.isRequired,
};

function HRStaffUploadWorkersResponseMessage({ response, onClose }) {
    const { translate } = useLocales();
    const theme = useTheme();

    const isOk = response.status === WORKERS_UPLOAD_STATUS.OK;
    const isNotOk = response.status === WORKERS_UPLOAD_STATUS.NOK;

    return (
        <Paper
            variant="outlined"
            sx={{
                py: 1,
                px: 2,
                my: 3,
                bgcolor:
                    (theme) =>
                        alpha(
                            isNotOk
                                ? theme.palette.error.main
                                : theme.palette.success.main, 0.08
                        ),
                borderColor:
                    (theme) =>
                        alpha(
                            isNotOk
                                ? theme.palette.error.main
                                : theme.palette.success.main, 0.24
                        ),
            }}
        >
            <Box sx={{ my: 1 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                    <Stack>
                        {isOk &&
                            <Typography variant="subtitle2">
                                {translate(
                                    'numberOfWorkersImportedSuccessfully',
                                    { numberOfWorkers: response.inserted },
                                )}
                            </Typography>
                        }
                        {isNotOk &&
                            <>
                                <Typography variant="subtitle1" pb={1}>
                                    {translate('pleaseFixErrorsAndReuploadFile')}
                                </Typography>
                                {response.errors.map((error) => (
                                    <Stack key={_uniqueid()} sx={{ my: 0.5 }}>
                                        <Typography
                                            variant="subtitle2"
                                            color={error.type === 'without_errors'
                                                ? theme.palette.primary.main
                                                : theme.palette.error.main
                                            }
                                        >
                                            {translate(error.type)}
                                        </Typography>
                                        <Box
                                            component="span"
                                            sx={{ typography: 'caption', pl: 2 }}
                                            color={error.type === 'without_errors'
                                                ? theme.palette.primary.main
                                                : theme.palette.error.main
                                            }
                                        >
                                            {translate('lines')}: {error.lines.join(', ')}
                                        </Box>
                                    </Stack>
                                ))}
                                {response.inserted
                                    ? <Typography variant="subtitle2">
                                        {translate(
                                            'numberOfWorkersWereInsertedSuccessfully',
                                            { numberOfWorkers: response.inserted })
                                        }
                                    </Typography>
                                    : null
                                }
                            </>
                        }
                    </Stack>
                    <Stack>
                        <Button variant="outlined" color="primary" onClick={onClose}>{translate('ok')}</Button>
                    </Stack>
                </Stack>
            </Box>
        </Paper>
    )
}

export default HRStaffUploadWorkersResponseMessage;