import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    data: [],
    filters: {
        userid: null,
        documenttypeid: null,
        expiresindays: null,
        missingdocs: 0,
    },
    error: '',
};

export const fetchHRStaffDocuments = createAsyncThunk(
    'hrStaffDocuments/fetchHRStaffDocuments',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/userdocument`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const hrStaffDocumentsSlice = createSlice({
    name: 'hrStaffDocumentsSlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                userid: null,
                documenttypeid: null,
                expiresindays: null,
                missingdocs: 0,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchHRStaffDocuments.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHRStaffDocuments.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.data = payload.data;
            state.alerts = payload.alerts;
        });
        builder.addCase(fetchHRStaffDocuments.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
    },
});

export const { setFilters, resetFilters } = hrStaffDocumentsSlice.actions;

export default hrStaffDocumentsSlice.reducer;

export const selectHRStaffDocumentsFilters = (state) => state.hrStaffDocuments.filters;
export const selectHRStaffDocumentsData = (state) => state.hrStaffDocuments.data;
export const selectHRStaffDocumentsLoading = (state) => state.hrStaffDocuments.isLoading;
export const selectHRStaffDocumentsError = (state) => state.hrStaffDocuments.error;
