import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  headers: {},
  filters: {
    startdate: null,
    enddate: null,
    worktypeid: 1,
    locationid: '',
    productid: '',
  },
  error: '',
};

export const fetchChecklistReport = createAsyncThunk(
  'checklistReport/fetchChecklistReport',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmapchecklist`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const checklistReportSlice = createSlice({
  name: 'checklistReportSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        worktypeid: 1,
        locationid: '',
        productid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchChecklistReport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchChecklistReport.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data.data;
      state.headers = createHeaders(payload.data.header);
    });
    builder.addCase(fetchChecklistReport.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = checklistReportSlice.actions;

export default checklistReportSlice.reducer;

export const selectChecklistReportFilters = (state) => state.operationsChecklistReport.filters;
export const selectChecklistReportData = (state) => state.operationsChecklistReport.data;
export const selectChecklistReportHeaders = (state) => state.operationsChecklistReport.headers;
export const selectChecklistReportLoading = (state) => state.operationsChecklistReport.isLoading;
export const selectChecklistReportError = (state) => state.operationsChecklistReport.error;

const createHeaders = (headers) => {
  const tableHead = [];
  Object.keys(headers).forEach((header) => {
    tableHead.push({ id: header, label: headers[header], sortable: true, show: true, align: 'left' });
  });

  return tableHead;
};
