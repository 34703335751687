// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const pt = {
  harvest_entries: 'Registos de Colheita',
  start_date: 'Início',
  end_date: 'Fim',
  search: 'Procurar',
  filters: 'Filtros',
  dashboard: 'Painel Controle',
  harvest: 'Colheita',
  entries: 'Registos Colheita',
  scrap: 'Refugo',
  qualityControl: 'Controle Qualidade',
  massMovement: 'Movimento Massa',
  harvestVsWaste: 'Colheita vs Refugo',
  productivity: 'Produtividade Colhedor',
  packaging: 'Embalamento',
  packagingSum: 'Produtividade',
  workMaps: 'Mapas Trabalho',
  checklistReport: 'Relatório Checklist',
  workHours: 'Horas Trabalho',
  fertirrigation: 'Fertirrigação',
  tanksList: 'Lista Cubas',
  tanksHistory: 'Relatório de Reenchimento',
  humanResources: 'Recursos Humanos',
  staff: 'Trabalhadores',
  staffDocuments: 'Documentos de Trabalhadores',
  workingNow: 'A Trabalhar Agora',
  inventoryManagement: 'Inventário',
  itemTypes: 'Tipos de Artigos',
  items: 'Artigos',
  stocks: 'Armazéns',
  stocksMovements: 'Movimentos de Stock',
  stockByDay: 'Stock por Data',
  consumption: 'Consumos',
  electricity: 'Eletricidade',
  water: 'Água',
  fuel: 'Combustível',
  parameters: 'Parâmetros',
  bonus: 'Bónus Colhedor',
  locations: 'Sectores',
  culture: 'Cultura',
  absenceTypes: 'Tipos Ausência',
  harvestDefectTypes: 'Controlo Qualidade Colheita',
  performanceTypes: 'Parâmetros de Comportamento do Trabalhador',
  documentTypes: 'Documentos',
  reportScheduler: 'Programador Relatórios',
  day: 'Data',
  worker_id: 'ID',
  name: 'Nome',
  location: 'Sector',
  yield: 'Embalagem',
  quantity: 'Qtd',
  hour: 'Hora',
  weight_kg: 'Peso (Kg)',
  weight: 'Peso',
  provider: 'Empresa',
  supervisor: 'Supervisor',
  no_data: 'Sem dados',
  edit: 'Editar',
  delete: 'Eliminar',
  dense: 'Compacto',
  rows_per_page: 'Linhas por página',
  clear_all: 'Limpar tudo',
  registered_by: 'Registado por',
  workers: 'Trabalhadores',
  providers: 'Empresas',
  supervisors: 'Supervisores',
  cancel: 'Cancelar',
  harvest_entries_edit: 'Editar registo de Colheita',
  harvest_scrap_edit: 'Editar registo de Refugo',
  harvest_quality_control_edit: 'Editar registo de Controlo de Qualidade',
  crop: 'Colheita',
  waste: 'Refugo',
  industrial_waste: 'Industrial',
  harvest_scrap: 'Refugo de Colheita',
  harvest_type: 'Cultura',
  harvest_scrap_create: 'Registar Refugo',
  create: 'Criar',
  update_successful: 'Actualização bem sucedida',
  successfully_created: 'Criado com sucesso',
  harvest_vs_waste: 'Colheita vs Refugo',
  waste_percent: 'Refugo %',
  industrial: 'Industrial',
  industrial_percent: 'Industrial ',
  total_waste: 'Total Refugo',
  total_waste_percent: 'Total Refugo %',
  harvest_mass_movement: 'Movimentos de Massa',
  harvest_productivity: 'Produtividade do Colhedor',
  time_harvest: 'Trabalhado (h)',
  speed: 'Velocidade (Kg/h)',
  speed_kg_h: 'Velocidade (Kg/h)',
  companies: 'Empresas',
  company: 'Empresa',
  package: 'Embalagem',
  worker: 'Trabalhador',
  harvest_packaging: 'Embalagem de colheita',
  start: 'Início',
  end: 'Fim',
  rest_time_min: 'Descanso (min)',
  hours: 'Horas',
  language: 'Idioma',
  harvest_quality_control: 'Controlo de Qualidade de Colheita',
  defect: 'Defeito',
  bonus_multiplier: 'Multiplicador de Bónus',
  done_by: 'Por',
  notes: 'Notas',
  working_now: 'A trabalhar agora',
  document_id: 'Id do documento',
  tax_id: 'NIF',
  social_security: 'Segurança Social',
  nationality: 'Nacionalidade',
  birth_date: 'Data de Nascimento',
  role: 'Cargo',
  male: 'Homem',
  female: 'Mulher',
  other: 'Outro',
  gender: 'Género',
  address: 'Morada',
  phone: 'Telefone',
  email: 'Email',
  work_type: 'Tipo de Trabalho',
  unclosed: 'Por fechar',
  work_maps: 'Mapas de Trabalho',
  work_maps_edit: 'Editar Mapa de Trabalho',
  work_hours_edit: 'Editar Horas de Trabalho',
  work_hours: 'Horas de Trabalho',
  map_hours_edit: 'Editar Mapa de Trabalho',
  map_time_in: 'Hora Ínicio',
  map_time_out: 'Hora Fim',
  map_time_rest: 'Pausa (min)',
  checklist_report: 'Relatório Checklist',
  settings: 'Configurações',
  home: 'Início',
  profile: 'Perfil',
  logout: 'Terminal Sessão',
  light: 'Claro',
  dark: 'Escuro',
  lowCondition: '{{fieldName}} baixo ({{value}})',
  highCondition: '{{fieldName}} alto ({{value}})',
  emptyCondiditon: 'Valor de {{fieldName}} inválido',
  drainageRaisedTo: 'Drenagem subiu para ({{value}})',
  drainageLoweredTo: 'Drenagem baixou para ({{value}})',
  tankAlert: 'Cuba sem recarga durante ({{value}}) dias',
  EC: 'EC',
  pH: 'pH',
  percentOfDrainage: '% de drenagem',
  percentOfHumidity: '% de humidade',
  temperature: 'Temperatura',
  ECInAndECOut: 'EC in + EC out',
  readingOneHour: '1ª Leitura',
  readingTwoHour: '2ª Leitura',
  readingThreeHour: '3ª Leitura',
  readingOnePercent: '% 1ª Leitura',
  readingTwoPercent: '% 2ª Leitura',
  readingThreePercent: '% 3ª Leitura',
  dailyPercent: 'Média Diária',
  list: 'Leituras',
  cumulative: 'Cumulativo',
  drainage: 'Drenagem',
  valve: 'Válvula',
  inPH: '[In] pH',
  outPH: '[Out] pH',
  inEC: '[In] EC',
  outEC: '[Out] EC',
  inVolume: '[In] Volume',
  inTotalVolume: '[In] Total Volume',
  outVolume: '[Out] Volume',
  percentage: 'Drenagem %',
  updatedAt: 'Última atualização',
  updatedBy: 'Atualizado por',
  createdAt: 'Criado em',
  action: 'Ações',
  fertirrigationDrainage: 'Drenagem Fertirrigação',
  message: 'Alerta',
  solved: 'Resolvido',
  yes: 'Sim',
  no: 'Não',
  substrate: 'Substrato',
  humidityPercent: 'Humidade %',
  ECmilliSiemensPerCentimeter: 'EC mS/cm',
  humiditySensorial: 'Humidade Sensorial',
  roots: 'Raízes',
  tanks: 'Cuba',
  view: 'Ver',
  itemType: 'Tipo',
  tank: 'Cuba',
  totalVolume: 'Capacidade',
  stock: 'Armazém Principal',
  refillHistory: 'Registo Reeenchimento',
  doneBy: 'Registado por',
  refillItems: 'Artigos reenchimento',
  additionalStock: 'Armazém Secundário',
  totalItems: 'Artigos',
  date: 'Data',
  volume: 'Volume',
  resolutionDay: 'Data Resolução',
  reading1: '1ª Leitura',
  reading2: '2ª Leitura',
  reading3: '3ª Leitura',
  percent: 'Percentagem',
  alerts: 'Alertas',
  information: 'Informação',
  back: 'Voltar',
  absenceBook: 'Livro de Ausências',
  absenceBookEdit: 'Editar Ausência',
  absenceType: 'Tipo de Ausência',
  durationInDays: 'Duração (dias)',
  all: 'Todos',
  fieldIsRequired: 'Campo obrigatório',
  workerFeedback: 'Feedback Trabalhador',
  timesheets: 'Registo de Ponto',
  payroll: 'Folhas de Pagamento',
  absenceBookCreate: 'Registar Ausência',
  timeOther: 'Tempo Outro',
  timeHarvest: 'Tempo Colheita',
  kg: 'Kg',
  kgHour: 'Kg/h',
  multiplier: 'Multiplicador',
  showTotals: 'Mostrar Totais',
  behavior: 'Comportamento',
  behaviorType: 'Tipo de comportamento',
  workerFeedbackCreate: 'Registar Feedback de Trabalhador',
  workerFeedbackEdit: 'Editar Feedback de Trabalhador',
  theValueShouldBeANumber: 'Deve ser um número',
  taxID: 'Identificação Fiscal',
  idNumber: 'Número de identidade',
  socialSecurityNumber: 'Segurança Social',
  typeOfContract: 'Tipo de contrato',
  salary: 'Salário',
  staffEdit: 'Editar dados do trabalhador',
  staffCreate: 'Criar Trabalhador',
  general: 'Informação Geral',
  legalInfo: 'Informação Legal',
  contract: 'Dados Contratuais',
  contractType: 'Tipo de contrato',
  id: 'ID',
  active: 'Activo',
  editInfo: 'Detalhes Registo',
  activateStaff: 'Activar Trabalhador(es)',
  inactivateStaff: 'Desactivar Trabalhador(es)',
  activateSingleStaffMember: 'Confirme que quer activar este trabalhador?',
  activateMultipleStaffMembers: 'Confirme que quer activar estes trabalhadores?',
  inactivateSingleStaffMember: 'Confirme que quer desactivar este trabalhador?',
  inactivateMultipleStaffMembers: 'Confirme que quer desactivar estes trabalhadores?',
  activate: 'Activar',
  inactivate: 'Desactivar',
  changeStaffActiveStatusSuccess: 'Estado de {{numberOfWorkers}} trabalhador(es) atualizado',
  documentType: 'Tipo de documento',
  expiryDate: 'Expira em',
  filename: 'Nome do ficheiro',
  editDocument: 'Editar Documento',
  addDocument: 'Adicionar Documento',
  link: 'Link',
  invalidLinkFormat: 'Link com formato inválido',
  upload: 'Carregar',
  download: 'Descarregar',
  activityTimeReport: 'Relatório Actividades',
  missingMandatoryDocs: 'Documentos obrigatórios em falta',
  expiresInDays: 'Expira em',
  workType: 'Tipo de trabalho',
  total: 'Total',
  field: 'Campo',
  low: 'Baixo',
  high: 'Alto',
  normal: 'Normal',
  consumptionElectricityCreate: 'Registar Leitura de Electricidade',
  consumptionElectricityEdit: 'Editar Leitura de Electricidade',
  consumptionWaterEdit: 'Editar Leitura de Água',
  consumptionWaterCreate: 'Registar Leitura de Água',
  fileIsNotFound: 'Ficheiro não encontrado',
  reading: 'Leitura',
  fuelType: 'Tipo de combustível',
  consumptionFuelEdit: 'Editar Leitura de Combustível',
  consumptionFuelCreate: 'Registar Leitura de Combustível',
  documents: 'Documentos',
  fileTooBig: 'Ficheiro demasiado grande. Tamanho máximo 10MB',
  harvestMassMovement: 'Movimento de Massa de Colheita',
  absenceParameters: 'Tipos de Ausência',
  pickingBonus: 'Bónus de Colheita',
  abbreviation: 'Sigla',
  editAbsenceParameters: 'Editar Tipo de Ausência',
  createAbsenceParameters: 'Criar Tipo de Ausência',
  description: 'Descrição',
  type: 'Tipo',
  bonusMultiplier: 'Multiplicador de Bónus',
  positive: 'Positivo',
  negative: 'Negativo',
  createWorkerFeedbackParameters: 'Criar Tipo de Feedback',
  editWorkerFeedbackParameters: 'Editar Tipo de Feedback',
  fromKgHour: 'A partir de (Kg/h)',
  toKgHour: 'Até (Kg/h)',
  bonusDollars: 'Bónus ($)',
  createPickingBonusParameters: 'Criar Parâmetro de Bónus de Colheita',
  editPickingBonusParameters: 'Editar Parâmetro de Bónus de Colheita',
  deleteDocumentTypesParameters: 'Eliminar Parâmetros de tipos de documentos',
  createDocumentTypesParameters: 'Criar Tipo de documento',
  editDocumentTypesParameters: 'Editar Tipo de documento',
  document: 'Documento',
  mandatory: 'Obrigatório',
  expiration: 'Validade',
  farm: 'Informação Agrícola',
  cropContainers: 'Embalamento',
  cropQualityControl: 'Controlo de Qualidade de Colheita',
  createCropParameters: 'Criar Cultura',
  harvestBonusPenalty: 'Penalização de Bónus (Multiplicador)',
  cropQualityControlParameters: 'Controlo de Qualidade de Colheita',
  createCropQualityControlParameters: 'Criar tipo de Defeito',
  editCropQualityControlParameters: 'Editar tipo de Defeito',
  downloadLimitReached: 'Limite de descarga atingido. Seleccione menos de {{limite}} ficheiros.',
  linkIsTooLong: 'Comprimento máximo de 100 caracteres',
  deleteCropContainersParameters: 'Eliminar Embalagens',
  containerName: 'Nome de Embalagem',
  capacityGr: 'Capacidade (gr)',
  editCropContainersParameters: 'Editar Embalagem',
  createCropContainersParameters: 'Criar Embalagem',
  createLocationsParameters: 'Criar Localização',
  editLocationsParameters: 'Editar Localização',
  irrigatorsPerVase: 'Irrigadores/Vaso',
  valveSubsector: 'Válvula/Subsector',
  sector: 'Sector',
  locationsParameters: 'Parâmetros de localização',
  cropContainersParameters: 'Parâmetros de embalagem',
  reporting: 'Relatórios',
  reportName: 'Nome do relatório',
  frequency: 'Frequência',
  schedule: 'Agenda',
  sendTo: 'Enviar para',
  ccTo: 'CC',
  createReportingParameters: 'Criar Programação de Relatório',
  editReportingParameters: 'Editar Programação de Relatório',
  cropParameters: 'Parâmetro de Colheita',
  stockName: 'Armazém',
  inventoryStocks: 'Stocks de inventário',
  stockMovements: 'Movimentos de Stock',
  stockCreate: 'Criar Armazém',
  stockMin: 'Nível de Stock minimo',
  unitsInStock: 'Unidades em Stock',
  totalQuantityInStock: 'Qtd. em Stock',
  level: 'Nível de Stock',
  item: 'Artigo',
  operation: 'Operação',
  sourceStock: 'De',
  destinyStock: 'Para',
  affectedQuantity: 'Qtd. Operada',
  initialQuantity: 'Qtd. Inicial',
  finalQuantity: 'Qtd. final',
  recentMovements: 'Operaçôes Recentes',
  operationTypes: 'Tipos de operação',
  itemTypesList: 'Tipos de Artigos',
  stocksList: 'Lista de Armazéns',
  itemsList: 'Lista de Artigos',
  unitType: 'Tipo de unidade',
  unitQuantity: 'Capacidade da Embalagem',
  unitInStock: 'Emb. em stock',
  quantityInStock: 'Disponível',
  inventoryItems: 'Artigos de armazém',
  itemCreate: 'Criar Artigo',
  itemEdit: 'Editar Artigo',
  itemTypeCreate: 'Criar Artigo',
  itemTypeEdit: 'Editar Tipo de Artigo',
  inventoryTypes: 'Tipos de artigos',
  access: 'Acesso',
  manageAccess: 'Acesso à App',
  addAccess: 'Dar acesso a App',
  password: 'Palavra-passe',
  userName: 'Nome de utilizador',
  onboardingLanguage: 'Idioma de Boas Vindas',
  portal: 'Portal',
  app: 'App',
  both: 'Ambos',
  portuguese: 'Português',
  english: 'Inglês',
  spanish: 'Espanhol',
  passwordReset: 'Nova palavra-passe',
  usernameIsTooLong: 'Máximo 100 caracteres ',
  usernameIsTooShort: 'Mínimo 8 caracteres ',
  passwordIsTooLong: 'Máximo 100 caracteres ',
  passwordIsTooShort: 'Mínimo 6 caracteres ',
  invalidPasswordFormat: 'Formato de palavra-chave inválida',
  deleteAccess: 'Eliminar Acesso',
  resetPassword: 'Redefinir palavra-passe',
  deleteAccessText: 'Eliminar acesso ao trabalhador?',
  viewStock: 'Ver Armazém',
  removeStockItemsBeforeDeletion: 'Armazém tem de estar vazio para ser eliminado',
  totalHarvest: 'Total Colheita',
  totalPackages: 'Total de Embalagens',
  productionSpeed: 'Média de Colheita',
  pickingPerformance: 'Produtividade de Colheita',
  industrialWaste: 'Industrial',
  pickedInKg: 'Colheita (Kg)',
  workedInHours: 'Trabalhado (h)',
  speedInKgHour: 'Velocidade (Kg/h)',
  previousDay: 'vs dia anterior',
  vsPreviousDay: 'vs dia anterior',
  harvestSlashWaste: 'Colheita / Refugo',
  drainageAlert: 'Alertas Fertirrigação',
  notSolved: 'Não resolvido',
  at: 'às',
  timeSpentOnTasks: 'Tempo gasto em tarefas',
  totalOfHoursSpentOnEachTask: 'Total gasto em cada tarefa',
  unclosedWorkMaps: 'Mapas por fechar',
  workMapsNeedClosing: '{{workMapsQuantity}} Mapas de trabalho precisam ser fechados',
  viewAll: 'Ver todos',
  allMapsAreClosedForThisDay: 'Todos os mapas deste dia foram fechados',
  accessManagement: 'Gestão Acessos',
  portalPermissions: 'Permissões',
  roleList: 'Cargo',
  moduleList: 'Módulo',
  canView: 'Pode ver',
  canEdit: 'Pode editar',
  canCreate: 'Pode criar',
  canDelete: 'Pode eliminar',
  menu: 'Menu',
  operations: 'Operações',
  changesAreNotSaved: 'Todas as alterações serão perdidas. Quer prosseguir?',
  passwordHint: 'Seis caracteres no mínimo, pelo menos uma letra e um número',
  logins: 'Acessos',
  loginEdit: 'Editar Acesso',
  loginCreate: 'Criar Acesso',
  signInToFarmUpPortal: 'Sign in to FarmUp Portal',
  university: 'Universidade',
  website: 'Website',
  whatsApp: 'WhatsApp',
  phoneContact: 'Telefone',
  enterYourDetailsBelow: 'Por favor introduza os seus dados abaixo',
  login: 'Login',
  loginIsRequired: 'É necessário efectuar login',
  passwordIsRequired: 'A palavra-passe é obrigatória',
  incorrectCredentials: 'Credenciais Incorretas',
  deletePopupTitle: 'Eliminar',
  deletePopupMessageOneItem: 'Confirme que quer eliminar este registo?',
  deletePopupMessageMultipleItems: 'Confirme que quer eliminar estes registos?',
  banned: 'Inactivo',
  selectDateRange: 'Seleccione o intervalo de datas',
  endDateMustBeLaterThanStartDate: 'A data de fim deve ser posterior à data de início',
  maximumRangeCannotBeMoreThanOneYear: 'Maximum range cannot be more than one year',
  inactive: 'Inactivo',
  status: 'Estado',
  sorryPageNotFound: 'Página não encontrada!',
  pageNotFound: '404 Página Não Encontrada',
  couldNotFindThePageYouAreLookingFor: 'Lamentamos, não foi possível encontrar a página que procurava. Talvez tenha digitado mal o URL? Não se esqueça de verificar a sua ortografia.',
  goToHome: 'Voltar a página inicial',
  showInactiveWorkers: 'Mostrar trabalhadores inactivos',
  withAccess: 'Com acesso',
  inactiveUsers: 'Inactivos',
  searchInHelp: 'Pesquisar em ajuda...',
  everyMonth: 'cada mês',
  everyMonthDaysShort: 'dia do mês',
  everyWeekDaysShort: 'dia da semana',
  everyHours: 'cada hora',
  everyMinutes: 'cada minuto',
  everyMinutesForHourPeriod: 'cada',
  yearOption: 'ano',
  monthOption: 'mês',
  weekOption: 'semana',
  dayOption: 'dia',
  hourOption: 'hora',
  minuteOption: 'minuto',
  rebootOption: 'reiniciar',
  prefixPeriod: 'Cada',
  prefixMonths: 'em',
  prefixMonthDays: 'no',
  prefixWeekDays: 'no',
  prefixWeekDaysForMonthAndYearPeriod: 'e',
  prefixHours: 'às',
  prefixMinutesForHourPeriod: 'às',
  suffixMinutesForHourPeriod: 'minuto(s)',
  sunday: 'Domingo',
  monday: 'Segunda-feira',
  tuesday: 'Terça-feira',
  wednesday: 'Quarta-feira',
  thursday: 'Quinta-feira',
  friday: 'Sexta-feira',
  saturday: 'Sábado',
  january: 'Janeiro',
  february: 'Fevereiro',
  march: 'Março',
  april: 'Abril',
  may: 'Maio',
  june: 'Junho',
  july: 'Julho',
  august: 'Agosto',
  september: 'Setembro',
  october: 'Outubro',
  november: 'Novembro',
  december: 'Dezembro',
  sundayShort: 'DOM',
  mondayShort: 'SEG',
  tuesdayShort: 'TER',
  wednesdayShort: 'QUA',
  thursdayShort: 'QUI',
  fridayShort: 'SEX',
  saturdayShort: 'SAB',
  januaryShort: 'JAN',
  februaryShort: 'FEV',
  marchShort: 'MAR',
  aprilShort: 'ABR',
  mayShort: 'MAI',
  juneShort: 'JUN',
  julyShort: 'JUL',
  augustShort: 'AGO',
  septemberShort: 'SET',
  octoberShort: 'OUT',
  novemberShort: 'NOV',
  decemberShort: 'DEZ',
  recordsWithTheSameWorkerIdAreNotAllowed: 'Registos com a mesma identificação de trabalhador não são permitidos',
  recordsWithTheSameFiscalNumberAreNotAllowed: 'Registos com o mesmo número fiscal não são permitidos',
  recordsWithTheSameFilenameAreNotAllowed: 'Registos com o mesmo nome de ficheiro não são permitidos',
  recordsWithTheSameNameAreNotAllowed: 'Registos com o mesmo nome não são permitidos',
  expirationDateIsRequiredForThisTypeOfDocument: 'A data de expiração é necessária para este tipo de documento',
  somethingWentWrongWhileCreatingStaffMember: 'Algo correu mal ao criar o trabalhador',
  recordsWithTheSameGroupAreNotAllowed: 'Registos com o mesmo grupo não são permitidos',
  workerHasAlreadyTheAccess: 'O trabalhador já tem o acesso',
  recordsWithTheSameUsernameAreNotAllowed: 'Registos com o mesmo nome de utilizador não são permitidos',
  somethingWentWrongWhileFetchingTheRequest: 'Algo correu mal ao realizar o pedido',
  outOfStock: 'Esgotado',
  lowStock: 'Stock baixo',
  inStock: 'Em stock',
  apply: 'Aplicar',
  editCropParameters: 'Editar Parâmetro de Cultura',
  formulation: 'Formulação',
  uploadFiles: 'Subir Ficheiro',
  maximumOneFileToUpload: 'Máximo de um ficheiro a carregar',
  downloadTemplate: 'Descarregar template',
  ok: 'OK',
  numberOfWorkersImportedSuccessfully: '{{numberOfWorkers}} trabalhadores importados com sucesso!',
  numberOfWorkersWereInsertedSuccessfully: '{{numberOfWorkers}} trabalhadores criados com sucesso!',
  wrong_workerid: 'Falta o ID do trabalhador ou contém caracteres não numéricos',
  already_exists: 'ID do trabalhador já existe',
  missing_field_name: 'Falta o nome do trabalhador',
  missing_field_company: 'Falta a identificação da empresa',
  missing_field_role: 'Falta o cargo',
  wrong_company: 'Empresa não encontrada',
  wrong_role: 'Cargo não encontrado',
  wrong_social_security: 'A Segurança Social deve conter 11 caracteres numéricos',
  wrong_birth_date: 'O formato da data de nascimento deve ser DD.MM.AAAA',
  wrong_contract_date: 'O formato da data do início do contrato deve ser DD.MM.AAAA',
  wrong_salary: 'O salário deve ser apenas caracteres numéricos',
  lines: 'Linhas',
  members: 'Trabalhadores',
  types: 'Tipos',
  editCompany: 'Editar Empresa',
  createCompany: 'Criar Empresa',
  harvestKg: 'Colheita (Kg)',
  save: 'Guardar',
  selected: 'seleccionados',
  workerHours: 'Horas Trabalhador',
  mapHours: 'Horas Mapa de trabalho',
  workerId: 'ID Trabalhador',
  permissions: 'Permissões',
  valueMustBeAnInteger: 'O valor deve ser um número inteiro',
  workTypes: 'Tipos de trabalhos',
  workTypesParameters: 'Work Types ParametersPT',
  editWorkTypesParameters: 'Editar Tipo de Trabalho',
  createWorkTypesParameters: 'Criar Tipo de Trabalho',
  daysOffPlanning: 'Planificador Folgas',
  monthPicker: 'Mês',
  thisGapShouldBeChangedInTheAbsenceBookSection: 'Esta folga tem de ser editada no Livro de Ausências',
  dropOrSelectFile: 'Arraste ou escolhe o ficheiro',
  dropFilesHereOrClickBrowseThroughYourMachine: 'Arraste o ficheiro aqui ou seleciona <1>pesquisar</1> no seu computador',
  harvestPackagingSummary: 'Resumo de Embalamento',
  without_errors: 'Sem erros',
  pleaseFixErrorsAndReuploadFile: 'Por favor corrija os erros e volte a subir o ficheiro',
  continue: 'Continuar',
  wrongUsernameOrPassword: 'Login ou palavra-passe incorretos',
  accountHasNoAccessToTheApplication: 'A sua conta não tem permissões para utilizar esta aplicação',
  details: 'Detalhes',
  stockEdit: 'Editar Armazém',
  harvestProductivityListTab: 'Por Sector',
  harvestProductivitySummaryTab: 'Sumário',
  harvestProductivityPickerBonusTooltip: 'Como é calculado este valor? ("Bonus mapa 1" + "Bonus mapa 2" + .... ) x Multiplicador',
  reportDescription: 'Descrição',
  sendNow: 'Enviar agora',
  reportRequestSent: 'Pedido de relatório enviado, resultados irão chegar ao email configurado',
  wrongDateFormat: 'Formato de data incorreto',
};

export default pt;
