import { useState } from 'react';
import { isSameDay, isSameMonth, getYear, isBefore, format } from 'date-fns';

import useLocales from '../../hooks/useLocales';

const normalizeDate = (date) => {
  if (!date) {
    return null;
  }

  // DatePicker automatically sets milliseconds to 0, so we need to do the same
  return new Date(date.setMilliseconds(0));
};

export default function useDateRangePicker(start, end) {
  const [open, setOpen] = useState(false);
  const locale = useLocales();

  const [endDate, setEndDate] = useState(() => normalizeDate(end));

  const [startDate, setStartDate] = useState(() => normalizeDate(start));

  const isError =
    (startDate && endDate && isBefore(new Date(endDate), new Date(startDate))) || false;

  const isRangeError = (endDate - startDate) > 31536000000;

  const currentYear = new Date().getFullYear();

  const startDateYear = startDate ? getYear(startDate) : null;

  const endDateYear = endDate ? getYear(endDate) : null;

  const isCurrentYear = currentYear === startDateYear && currentYear === endDateYear;

  const isSameDays =
    startDate && endDate ? isSameDay(new Date(startDate), new Date(endDate)) : false;

  const isSameMonths =
    startDate && endDate ? isSameMonth(new Date(startDate), new Date(endDate)) : false;

  const localizedDate = (date, newFormat) => {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm, { locale: locale.currentLang.fnsLocale }) : '';
  };

  const standardLabel = `${localizedDate(startDate)} - ${localizedDate(endDate)}`;

  const getShortLabel = () => {
    if (isCurrentYear) {
      if (isSameMonths) {
        if (isSameDays) {
          return localizedDate(endDate, 'dd MMM yy');
        }
        return `${localizedDate(startDate, 'dd')} - ${localizedDate(endDate, 'dd MMM yy')}`;
      }
      return `${localizedDate(startDate, 'dd MMM')} - ${localizedDate(endDate, 'dd MMM yy')}`;
    }
    return `${localizedDate(startDate, 'dd MMM yy')} - ${localizedDate(endDate, 'dd MMM yy')}`;
  };

  const onChangeStartDate = (newValue) => {
    setStartDate(newValue);
  };

  const onChangeEndDate = (newValue) => {
    if (isError) {
      setEndDate(null);
    }
    setEndDate(newValue);
  };

  const onReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return {
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onReset,
    isSelected: !!startDate && !!endDate,
    isError,
    isRangeError,
    label: standardLabel || '',
    shortLabel: getShortLabel() || '',
    setStartDate,
    setEndDate,
  };
}
