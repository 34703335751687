import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Stack,
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { DatePicker, CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import useResponsive from '../../hooks/useResponsive';
import useLocales from '../../hooks/useLocales';

DateRangePickerModal.propTypes = {
  open: PropTypes.bool,
  isError: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onChangeEndDate: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  variant: PropTypes.oneOf(['input', 'calendar']),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  onApply: PropTypes.func,
  isRangeError: PropTypes.bool,
  startDateViews: PropTypes.array,
  endDateViews: PropTypes.array,
};

export default function DateRangePickerModal({
  title,
  variant = 'input',
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  open,
  onClose,
  isError,
  onApply,
  isRangeError,
  startDateViews,
  endDateViews,
}) {
  const isDesktop = useResponsive('up', 'md');
  const { translate } = useLocales();

  const isCalendarView = variant === 'calendar';

  const locale = useLocales();

  return (
    <Dialog
      fullWidth
      maxWidth={isCalendarView ? false : 'xs'}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          ...(isCalendarView && {
            maxWidth: 720,
          }),
        },
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <DialogContent
        sx={{
          ...(isCalendarView &&
            isDesktop && {
            overflow: 'unset',
          }),
        }}
      >
        <Stack
          spacing={isCalendarView ? 3 : 2}
          direction={isCalendarView && isDesktop ? 'row' : 'column'}
          justifyContent="center"
          sx={{
            pt: 1,
            '& .MuiCalendarPicker-root': {
              ...(!isDesktop && {
                width: 'auto',
              }),
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale.currentLang.fnsLocale}>
            {isCalendarView ? (
              <>
                <Paper
                  variant="outlined"
                  sx={{ borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' }}
                >
                  <CalendarPicker
                    date={startDate}
                    onChange={onChangeStartDate}
                    minDate={new Date('2019-01-01')}
                    views={startDateViews}
                  />
                </Paper>

                <Paper
                  variant="outlined"
                  sx={{ borderRadius: 2, borderColor: 'divider', borderStyle: 'dashed' }}
                >
                  <CalendarPicker
                    date={endDate}
                    onChange={onChangeEndDate}
                    minDate={new Date('2019-01-01')}
                    views={endDateViews}
                  />
                </Paper>
              </>
            ) : (
              <>
                <DatePicker
                  label={translate('start_date')}
                  value={startDate}
                  onChange={onChangeStartDate}
                  renderInput={(params) => <TextField {...params} />}
                  views={startDateViews}
                />

                <DatePicker
                  label={translate('end_date')}
                  value={endDate}
                  onChange={onChangeEndDate}
                  renderInput={(params) => <TextField {...params} />}
                  views={endDateViews}
                />
              </>
            )}
          </LocalizationProvider>
        </Stack>

        {isError && (
          <FormHelperText error sx={{ px: 2 }}>
            {translate('endDateMustBeLaterThanStartDate')}
          </FormHelperText>
        )}
        {isRangeError && (
          <FormHelperText error sx={{ px: 2 }}>
            {translate('maximumRangeCannotBeMoreThanOneYear')}
          </FormHelperText>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {translate('cancel')}
        </Button>

        <Button disabled={isError || isRangeError} variant="contained" onClick={onApply}>
          {translate('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
