import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import useLocales from '../../../hooks/useLocales';
import IMStockRecentMovementsTable from '../list/IMStockRecentMovementsTable';
import {
    fetchIMStockMovementsById,
    selectIMStockMovementsById,
    selectIMStocksLoading,
} from '../../../redux/slices/imStocksSlice';

IMStockRecentMovementsModal.propTypes = {
    passedData: PropTypes.number,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default function IMStockRecentMovementsModal({ open, handleClose, passedData }) {
    const { translate } = useLocales();
    const dispatch = useDispatch();

    const stockMovements = useSelector(selectIMStockMovementsById);
    const isLoading = useSelector(selectIMStocksLoading);

    useEffect(() => {
        dispatch(fetchIMStockMovementsById(passedData));
    }, [dispatch, passedData]);

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="stock-movements" maxWidth="lg" fullWidth>
            <DialogTitle id="stock-movements">
                {translate('recentMovements')}
            </DialogTitle>
            <DialogContent>
                <IMStockRecentMovementsTable isLoading={isLoading} tableDataList={stockMovements} isModal />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
                    {translate('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
