import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { fDateUnix } from '../../utils/formatTime';
import computeAverage from '../../utils/computeAverage';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  dataSum: [],
  filters: {
    startdate: null,
    enddate: null,
    locationid: '',
  },
  error: '',
};

export const fetchFertirrigationSubstrate = createAsyncThunk(
  'fertirrigationSubstrate/fetchFertirrigationSubstrate',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/substrate`, {
        params,
      });

      return response.data.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);


export const deleteFertirrigationSubstrate = createAsyncThunk(
  'fertirrigationSubstrate/deleteFertirrigationSubstrate',
  async ({ device, data }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/substrate`, {
        data: {
          ids: data,
          device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const fertirrigationSubstrateSlice = createSlice({
  name: 'fertirrigationSubstrateSlice',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        locationid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFertirrigationSubstrate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFertirrigationSubstrate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.substrates;
      state.alerts = payload.alerts;
      state.dataSum = getDataSum(payload.substrates);
    });
    builder.addCase(deleteFertirrigationSubstrate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFertirrigationSubstrate.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteFertirrigationSubstrate.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchFertirrigationSubstrate.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setFilters, resetFilters } = fertirrigationSubstrateSlice.actions;

export default fertirrigationSubstrateSlice.reducer;

export const selectFertirrigationSubstrateFilters = (state) => state.fertirrigationSubstrate.filters;
export const selectFertirrigationSubstrateData = (state) => state.fertirrigationSubstrate.data;
export const selectFertirrigationSubstrateSumData = (state) => state.fertirrigationSubstrate.dataSum;
export const selectFertirrigationSubstrateAlertData = (state) => state.fertirrigationSubstrate.alerts;
export const selectFertirrigationSubstrateLoading = (state) => state.fertirrigationSubstrate.isLoading;
export const selectFertirrigationSubstrateError = (state) => state.fertirrigationSubstrate.error;

const getDataSum = (substrates) => {
  const groupedSubstrates = [];

  substrates.forEach(substrate => {
    const existingSubstrateIndex = groupedSubstrates.findIndex(updatedDataItem =>
      fDateUnix(updatedDataItem.date) === fDateUnix(substrate.date) &&
      updatedDataItem.valve === substrate.valve &&
      updatedDataItem.locationName === substrate.locationName
    );

    if (existingSubstrateIndex >= 0) {
      groupedSubstrates[existingSubstrateIndex].ph.push(substrate.ph);
      groupedSubstrates[existingSubstrateIndex].ec.push(substrate.ec);
      groupedSubstrates[existingSubstrateIndex].humidity.push(substrate.humidity);
      groupedSubstrates[existingSubstrateIndex].temperature.push(substrate.temperature);
      groupedSubstrates[existingSubstrateIndex].manualHumidity.push(substrate.manualHumidity);
      groupedSubstrates[existingSubstrateIndex].roots.push(substrate.roots);
      return;
    }

    groupedSubstrates.push({
      date: substrate.date,
      locationName: substrate.locationName,
      valve: substrate.valve,
      ph: [substrate.ph],
      ec: [substrate.ec],
      humidity: [substrate.humidity],
      temperature: [substrate.temperature],
      manualHumidity: [substrate.manualHumidity],
      roots: [substrate.roots]
    });
  });

  return groupedSubstrates.map(item => ({
    ...item,
    ph: computeAverage(item.ph),
    ec: computeAverage(item.ec),
    humidity: computeAverage(item.humidity),
    temperature: computeAverage(item.temperature),
    manualHumidity: computeAverage(item.manualHumidity),
    roots: computeAverage(item.roots),
  }));
};
