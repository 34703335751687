import * as React from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useLocales from '../../../hooks/useLocales';
import { deleteHRStaffDocuments } from '../../../redux/slices/hrStaffSlice';
import { fetchHRStaffDocuments, selectHRStaffDocumentsFilters } from '../../../redux/slices/hrStaffDocumentsSlice';
import { PATH_DASHBOARD } from '../../../routes/paths';

HRStaffDocumentsDeleteModal.propTypes = {
  passedData: PropTypes.any,
  open: PropTypes.bool,
  single: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function HRStaffDocumentsDeleteModal({ passedData, open, handleClose, single }) {
  const dispatch = useDispatch();
  const location  = useLocation();
  const { translate } = useLocales();
  const filters = useSelector(selectHRStaffDocumentsFilters);

  const handleDelete = () => {
    dispatch(deleteHRStaffDocuments({ device: window.navigator.userAgent, ids: passedData }))
      .then(() => {
        if (location.pathname === PATH_DASHBOARD.humanResources.staff) {
          dispatch(fetchHRStaffDocuments({ ...filters }));
        }
        handleClose();
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="delete-staff-documents-dialog">
      <DialogTitle id="delete-staff-documents-dialog">
        {translate('deletePopupTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {single ? translate('deletePopupMessageOneItem') : translate('deletePopupMessageMultipleItems')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
          {translate('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
