import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import _uniqueId from 'lodash/uniqueId';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

const TableCellWithBorders = styled(TableCell)(() => ({
  border: '1px solid lightgrey',
  boxShadow: 'none !important',
  borderRadius: 'unset !important',
}));

TableHeadCustom.propTypes = {
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  headers: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  sx: PropTypes.object,
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  headers,
  sx,
}) {
  const { translate } = useLocales();
  const CellComponent = headers ? TableCellWithBorders : TableCell;

  return (
    <TableHead sx={sx}>
      {headers && (
        <TableRow>
          {headers.map(({ label, cols }) => (
            <TableCellWithBorders align="center" key={_uniqueId()} colSpan={cols}>
              {label}
            </TableCellWithBorders>
          ))}
        </TableRow>
      )}

      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event) => onSelectAllRows(event.target.checked)}
            />
          </TableCell>
        )}

        {headLabel.map(
          (headCell) =>
            headCell.show && (
              <Tooltip
                title={translate(headCell.tooltip)}
                key={headCell.id}
                placement="top"
                arrow
              >
                <CellComponent
                  align={headCell.align || 'left'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                    color: headCell.color || 'text.main',
                  }}
                >
                  {headCell.sortable && onSort ? (
                    <TableSortLabel
                      hideSortIcon
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => onSort(headCell.id)}
                    >
                      {translate(headCell.label)}{headCell.tooltip && '*'}

                      {orderBy === headCell.id ? (
                        <Box sx={{ ...visuallyHidden }}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    translate(headCell.label)
                  )}
                </CellComponent>
              </Tooltip>
            )
        )}
      </TableRow>
    </TableHead>
  );
}
