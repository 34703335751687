import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  inputFormat: PropTypes.string,
  disabled: PropTypes.bool
};

export default function RHFDatePicker({ name, label, inputFormat = 'dd/MM/yyyy', disabled }) {
  const { translate } = useLocales();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          label={translate(`${label}`)}
          inputFormat={inputFormat}
          error={!!error}
          disabled={disabled}
          renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} fullWidth />}
        />
      )}
    />
  );
}
