import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';

import { resetHRStaffMemberPassword } from '../../../redux/slices/hrStaffSlice';

import transformEmptyStringToUndefined from '../../../utils/transformEmptyStringToUndefined';

import { passwordValidationRegex } from '../edit/HRStaffAccessForm';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

HRStaffDocumentsDeleteModal.propTypes = {
  passedData: PropTypes.any,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function HRStaffDocumentsDeleteModal({ passedData: id, open, handleClose }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const accessSchema = Yup.object().shape({
    password: Yup
      .string()
      .transform(transformEmptyStringToUndefined)
      .max(100, translate('passwordIsTooLong'))
      .min(6, translate('passwordIsTooShort'))
      .when({
        is: val => val,
        then: Yup.string().matches(
          passwordValidationRegex,
          translate('invalidPasswordFormat')
        ),
        otherwise: Yup.string().notRequired(),
      })
      .required(translate('fieldIsRequired')),
  });

  const methods = useForm({
    resolver: yupResolver(accessSchema),
    defaultValues: {
      password: '',
    }
  });

  const onSubmit = (data) => {
    dispatch(resetHRStaffMemberPassword({ password: data.password, id })).then((res) => {
      if (res.error) {
        enqueueSnackbar(res.payload, { variant: 'error', delay: 2000 });
        return;
      }

      enqueueSnackbar(translate('update_successful'), { delay: 2000 });
      handleClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="staff-password-reset"
      sx={{
        '.MuiPaper-root': {
          minWidth: { xs: '70%', sm: '50%', md: '40%', lg: '30%' },
        }
      }}
    >
      <DialogTitle id="staff-password-reset">
        {translate('resetPassword')}
      </DialogTitle>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)} >
        <DialogContent>
          <RHFTextField
            fullWidth
            name="password"
            helperText={!methods.formState.errors.password
              ? translate('passwordHint')
              : methods.formState.errors.password.message
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" autoFocus onClick={handleClose}>
            {translate('cancel')}
          </Button>
          <Button variant="contained" color="error" type="submit" autoFocus>
            {translate('reset')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog >
  );
}
