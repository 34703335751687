import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

RHFTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFTimePicker({ name, label }) {
  const { translate } = useLocales();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          {...field}
          ampm={false}
          inputFormat="HH:mm"
          mask="__:__"
          label={translate(`${label}`)}
          error={!!error}
          renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} fullWidth />}
        />
      )}
    />
  );
}
