import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    cropQualityControlParameters: [],
    cropQualityControlParametersById: {},
    error: '',
};

export const fetchParametersCropQualityControl = createAsyncThunk(
    'parametersCropQualityControl/fetchParametersCropQualityControl',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype?type=1`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersCropQualityControl = createAsyncThunk(
    'parametersCropQualityControl/deleteParametersCropQualityControl',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersCropQualityControl = createAsyncThunk(
    'parametersCropQualityControl/createParametersCropQualityControl',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersCropQualityControlById = createAsyncThunk(
    'parametersCropQualityControl/fetchParametersCropQualityControlById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersCropQualityControl = createAsyncThunk(
    'parametersCropQualityControl/updateParametersCropQualityControl',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersCropQualityControlSlice = createSlice({
    name: 'parametersCropQualityControlSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersCropQualityControl.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCropQualityControl.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.cropQualityControlParameters = payload.data;
        });
        builder.addCase(fetchParametersCropQualityControl.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersCropQualityControl.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersCropQualityControl.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersCropQualityControl.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersCropQualityControl.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersCropQualityControl.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersCropQualityControl.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersCropQualityControlById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.cropQualityControlParametersById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersCropQualityControlById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCropQualityControlById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersCropQualityControl.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersCropQualityControl.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersCropQualityControl.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersCropQualityControlSlice.reducer;

export const selectParametersCropQualityControlData = (state) => state.parametersCropQualityControl.cropQualityControlParameters;
export const selectParametersCropQualityControlById = (state) => state.parametersCropQualityControl.cropQualityControlParametersById;
export const selectParametersCropQualityControlLoading = (state) => state.parametersCropQualityControl.isLoading;
export const selectParametersCropQualityControlError = (state) => state.parametersCropQualityControl.error;
