import { t as translate } from 'i18next';

function getErrorMessage(errorCode) {
    switch (errorCode) {
        case 3:
            return translate('wrongUsernameOrPassword');
        case 7:
            return translate('accountHasNoAccessToTheApplication');
        case 204:
            return translate('recordsWithTheSameWorkerIdAreNotAllowed');
        case 208:
            return translate('recordsWithTheSameFiscalNumberAreNotAllowed');
        case 444:
            return translate('recordsWithTheSameFileNameAreNotAllowed');
        case 446:
            return translate('expirationDateIsRequiredForThisTypeOfDocument');
        case 223:
        case 403:
        case 463:
        case 483:
        case 363:
        case 343:
            return translate('recordsWithTheSameNameAreNotAllowed');
        case 83:
            return translate('recordsWithTheSameGroupAreNotAllowed');
        case 251:
            return translate('recordsWithTheSameUsernameAreNotAllowed');
        case 253:
            return translate('workerHasAlreadyTheAccess');
        default:
            return translate('somethingWentWrongWhileFetchingTheRequest');
    }
}

export default getErrorMessage;