// @mui
import { Skeleton, Card, Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function TableSkeleton() {
  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 20px',
        }}
      >
        <Skeleton
          variant="rectangular"
          width={160}
          height={56}
          sx={{ borderRadius: 1, flexShrink: 0, marginRight: 3 }}
        />
        <Skeleton
          variant="rectangular"
          width={160}
          height={56}
          sx={{ borderRadius: 1, flexShrink: 0, marginRight: 3 }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ maxWidth: '100%', width: '100%', borderRadius: 1, float: 'none', overflow: 'hidden' }}
          height={56}
        />
      </Box>
      {[...Array(15)].map((_, i) => (
        <Box
          key={i}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 20px',
          }}
        >
          <Skeleton sx={{ marginRight: 3 }} variant="text" width={160} height={30} />
          <Skeleton sx={{ marginRight: 3 }} variant="text" width={160} height={30} />
          <Skeleton sx={{ marginRight: 3 }} variant="text" width={160} height={30} />
          <Skeleton sx={{ marginRight: 3 }} variant="text" width={160} height={30} />
          <Skeleton
            variant="text"
            sx={{ maxWidth: '100%', width: '100%', float: 'none', overflow: 'hidden' }}
            height={40}
          />
        </Box>
      ))}
    </Card>
  );
}
