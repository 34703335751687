import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import useLocales from '../../../hooks/useLocales';

import { Upload } from '../../../components/upload';
import LoadingBackground from '../../../components/LoadingBackground';
import HRStaffUploadWorkersResponseMessage from './HRStaffUploadWorkersResponseMessage';

import { fetchHRStaffTemplate, uploadStaffMembersByFile } from '../../../redux/slices/hrStaffSlice';

import downloadFileFromBase64String from '../../../utils/downloadFileFromBase64String';
import formatFileToBase64 from '../../../utils/formatFileToBase64';
import getErrorMessage from '../../../utils/getErrorMessage';

HRStaffUploadWorkersByFileModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function HRStaffUploadWorkersByFileModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [error, setError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const [files, setFiles] = useState([]);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      setError('');
      setResponse(null);
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (newFiles.length > 1) {
        setError(translate('maximumOneFileToUpload'));
        return;
      }

      setFiles([...newFiles]);
    },
    [translate]
  );

  const handleRemoveFile = (inputFile) => {
    setError('');
    setResponse(null);
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleResponseClose = () => {
    setResponse(null);
    setError('');
    setFiles([]);
  };

  const downloadTemplate = () => {
    dispatch(fetchHRStaffTemplate()).then((res) => {
      const { filename } = res.payload.data.document;
      const { file } = res.payload.data.document;
      downloadFileFromBase64String(file, filename);
    });
  };

  const upload = async () => {
    const fileInBase64Format = await formatFileToBase64(files[0]);
    setResponse(null);
    setIsLoading(true);

    const response = await dispatch(uploadStaffMembersByFile({ file: fileInBase64Format }));

    setIsLoading(false);

    if (response.error) {
      const code = response.error.errors ? response.error.errors[0].code : null;
      const message = getErrorMessage(code);
      enqueueSnackbar(message, { variant: 'error', delay: 2000 });

      return;
    }

    setResponse(response.payload.data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="upload-worker-by-file-dialog"
    >
      <DialogTitle id="upload-worker-by-file-dialog">
        {translate('uploadFiles')}
      </DialogTitle>
      <DialogContent
        sx={{ position: 'relative' }}
      >
        {isLoading && <LoadingBackground />}
        {response && <HRStaffUploadWorkersResponseMessage response={response} onClose={handleResponseClose} />}
        <Upload
          multiple
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemoveFile}
          accept={[
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ]}
          maxSize={10 * 1024 * 1024}
        />
        {!!error && (
          <FormHelperText error sx={{ px: 2 }}>
            {error}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          {translate('cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={downloadTemplate}>
          {translate('downloadTemplate')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus
          disabled={!files.length || isLoading}
          onClick={upload}
        >
          {translate('upload')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
