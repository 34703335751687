import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    refillItems: [],
    error: '',
};

export const fetchFertirrigationTankRefillItems = createAsyncThunk(
    'fertirrigationTankRefillItems/fetchFertirrigationTankRefillItems',
    async (id, { rejectWithValue }) => {
        try {
            const response =
                await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/tankstockarticle/getbyrefillid/${id}`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const fertirrigationTankRefillItemsSlice = createSlice({
    name: 'fertirrigationTankRefillItemsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchFertirrigationTankRefillItems.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFertirrigationTankRefillItems.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.refillItems = payload.data;
        });
        builder.addCase(fetchFertirrigationTankRefillItems.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
    },
});

export default fertirrigationTankRefillItemsSlice.reducer;

export const selectFertirrigationTankRefillItems = (state) => state.fertirrigationTankRefillItems.refillItems;
export const selectFertirrigationTankRefillItemsLoading = (state) => state.fertirrigationTankRefillItems.isLoading;
export const selectFertirrigationTankRefillItemsError = (state) => state.fertirrigationTankRefillItems.error;
