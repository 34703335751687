import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    FormControl,
    InputLabel,
    Box,
    Stack,
    Button,
    Drawer,
    Divider,
    IconButton,
    Typography,
    Select,
    MenuItem,
} from '@mui/material';

import { NAVBAR } from '../../../config';

import useLocales from '../../../hooks/useLocales';

import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';

import {
    selectOperationTypes,
    fetchOperationTypes,
    fetchStocksList,
    fetchItemsList,
    fetchItemTypesList,
    selectStocksList,
    selectItemsList,
    selectItemTypesList,
} from '../../../redux/slices/selectorsSlice';
import { selectIMStockMovementsFilters, setFilters } from '../../../redux/slices/imStockMovementsSlice';

IMStockMovementsTableFilterSidebar.propTypes = {
    isOpen: PropTypes.bool,
    onResetAll: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
};

export default function IMStockMovementsTableFilterSidebar({ isOpen, onResetAll, onOpen, onClose }) {
    const dispatch = useDispatch();
    const { translate } = useLocales();

    const operationTypesList = useSelector(selectOperationTypes);
    const stocksList = useSelector(selectStocksList);
    const itemsList = useSelector(selectItemsList);
    const itemTypesList = useSelector(selectItemTypesList);

    const filters = useSelector(selectIMStockMovementsFilters);

    useEffect(() => {
        dispatch(fetchOperationTypes());
        dispatch(fetchStocksList());
        dispatch(fetchItemsList());
        dispatch(fetchItemTypesList());
    }, [dispatch]);

    const handleFilterChange = (filter) => {
        dispatch(setFilters(filter));
    };
    const handleClearAll = () => {
        onResetAll();
    };

    return (
        <>
            <Button
                sx={{ padding: '8px 24px' }}
                disableRipple
                color="inherit"
                endIcon={<Iconify icon={'ic:round-filter-list'} />}
                onClick={onOpen}
            >
                {translate('filters')}
            </Button>
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={onClose}
                PaperProps={{
                    sx: { width: NAVBAR.BASE_WIDTH },
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {translate('filters')}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>
                <Divider />
                <Scrollbar>
                    <Stack spacing={3} sx={{ p: 3 }}>
                        <Stack spacing={1}>
                            <FormControl fullWidth>
                                <InputLabel id="operationTypes">{translate('operationTypes')}</InputLabel>
                                <Select
                                    labelId="operationTypes"
                                    id="operationTypes"
                                    value={filters.operationtypeid || ''}
                                    label={translate('operationTypes')}
                                    onChange={(e) => handleFilterChange({ operationtypeid: e.target.value || null })}
                                >
                                    <MenuItem value="">{translate('all')}</MenuItem>
                                    {operationTypesList?.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={1}>
                            <FormControl fullWidth>
                                <InputLabel id="stocksList">{translate('stocksList')}</InputLabel>
                                <Select
                                    labelId="stocksList"
                                    id="stocksList"
                                    value={filters.stockid || ''}
                                    label={translate('stocksList')}
                                    onChange={(e) => handleFilterChange({ stockid: e.target.value || null })}
                                >
                                    <MenuItem value="">{translate('all')}</MenuItem>
                                    {stocksList?.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={1}>
                            <FormControl fullWidth>
                                <InputLabel id="itemsList">{translate('itemsList')}</InputLabel>
                                <Select
                                    labelId="itemsList"
                                    id="itemsList"
                                    value={filters.articleid || ''}
                                    label={translate('itemsList')}
                                    onChange={(e) => handleFilterChange({ articleid: e.target.value || null })}
                                >
                                    <MenuItem value="">{translate('all')}</MenuItem>
                                    {itemsList?.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack spacing={1}>
                            <FormControl fullWidth>
                                <InputLabel id="itemTypesList">{translate('itemTypesList')}</InputLabel>
                                <Select
                                    labelId="itemTypesList"
                                    id="itemTypesList"
                                    value={filters.articletypeid || ''}
                                    label={translate('itemTypesList')}
                                    onChange={(e) => handleFilterChange({ articletypeid: e.target.value || null })}
                                >
                                    <MenuItem value="">{translate('all')}</MenuItem>
                                    {itemTypesList?.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                </Scrollbar>
                <Box sx={{ p: 3 }}>
                    <Button
                        fullWidth
                        size="large"
                        color="inherit"
                        variant="outlined"
                        onClick={handleClearAll}
                        startIcon={<Iconify icon={'ic:round-clear-all'} />}
                    >
                        {translate('clear_all')}
                    </Button>
                </Box>
            </Drawer>
        </>
    );
}
