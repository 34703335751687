import React from 'react';
import PropTypes from 'prop-types';
import { Box, TablePagination, FormControlLabel, Switch } from '@mui/material';
import useLocales from '../../hooks/useLocales';

TableFooter.propTypes = {
    dataFiltered: PropTypes.array.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    dense: PropTypes.bool,
    onChangeDense: PropTypes.func.isRequired,
    rowsPerPageOptions: PropTypes.array,
};

function TableFooter({
    dataFiltered,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    dense = false,
    onChangeDense,
    rowsPerPageOptions = [25, 50, 100]
}) {
    const { translate } = useLocales();

    return (
        <Box sx={{ position: 'relative' }}>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                labelRowsPerPage={translate('rows_per_page')}
                component="div"
                count={dataFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
            <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label={translate('dense')}
                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
        </Box>
    )
}

export default TableFooter;