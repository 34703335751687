import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    imItems: [],
    imItemById: {},
    imItemStocks: [],
    imItemMovements: [],
    imItemTypes: [],
    imItemTypeById: {},
    filters: {
        stockid: null,
        articletypeid: null,
    },
    error: '',
};

export const fetchIMItems = createAsyncThunk(
    'imItems/fetchIMItems',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/article`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMItemTypes = createAsyncThunk(
    'imItems/fetchIMItemTypes',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/articletype`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMItemById = createAsyncThunk(
    'imItems/fetchIMItemById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/article/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMItemStocksById = createAsyncThunk(
    'imStocks/fetchIMItemStocksById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/stockarticle?articleid=${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMItemMovementsById = createAsyncThunk(
    'imStocks/fetchIMItemMovementsById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_HOST_API_KEY}/operationHistory/recent-operations?articleid=${id}`
            );

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchIMItemTypeById = createAsyncThunk(
    'imStocks/fetchIMItemTypeById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_HOST_API_KEY}/articletype/${id}`
            );

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateIMItem = createAsyncThunk(
    'imItems/updateIMItem',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/article`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createIMItem = createAsyncThunk(
    'imItems/createIMItem',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/article`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateIMItemType = createAsyncThunk(
    'imItems/updateIMItemType',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/articletype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createIMItemType = createAsyncThunk(
    'imItems/createIMItemType',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/articletype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteIMItems = createAsyncThunk(
    'imItems/deleteIMItems',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/article`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);


export const deleteIMItemTypes = createAsyncThunk(
    'imItems/deleteIMItemTypes',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/articletype`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const imItemsSlice = createSlice({
    name: 'imItemsSlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                stockid: null,
                articletypeid: null,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchIMItems.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMItems.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.imItems = payload.data;
        });
        builder.addCase(fetchIMItems.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchIMItemTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMItemTypes.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.imItemTypes = payload.data;
        });
        builder.addCase(fetchIMItemTypes.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchIMItemById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imItemById = payload;
            state.error = '';
        });
        builder.addCase(fetchIMItemById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMItemById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchIMItemMovementsById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imItemMovements = payload;
            state.error = '';
        });
        builder.addCase(fetchIMItemMovementsById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMItemMovementsById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchIMItemTypeById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imItemTypeById = payload;
            state.error = '';
        });
        builder.addCase(fetchIMItemTypeById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMItemTypeById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchIMItemStocksById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.imItemStocks = payload;
            state.error = '';
        });
        builder.addCase(fetchIMItemStocksById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMItemStocksById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateIMItem.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateIMItem.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateIMItem.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(createIMItem.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createIMItem.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createIMItem.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(createIMItemType.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createIMItemType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createIMItemType.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(updateIMItemType.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateIMItemType.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateIMItemType.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(deleteIMItems.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteIMItems.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteIMItems.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(deleteIMItemTypes.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteIMItemTypes.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteIMItemTypes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export const { setFilters, resetFilters } = imItemsSlice.actions;

export default imItemsSlice.reducer;

export const selectIMItemsData = (state) => state.imItems.imItems;
export const selectIMItemTypes = (state) => state.imItems.imItemTypes;
export const selectIMItemTypeById = (state) => state.imItems.imItemTypeById;
export const selectIMItemById = (state) => state.imItems.imItemById;
export const selectIMItemsLoading = (state) => state.imItems.isLoading;
export const selectIMItemsError = (state) => state.imItems.error;
export const selectIMItemsFilters = (state) => state.imItems.filters;
export const selectIMItemMovements = (state) => state.imItems.imItemMovements;
export const selectIMItemStocks = (state) => state.imItems.imItemStocks;
