import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    cropParameters: [],
    cropParametersById: {},
    error: '',
};

export const fetchParametersCrop = createAsyncThunk(
    'parametersCrop/fetchParametersCrop',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/products`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersCrop = createAsyncThunk(
    'parametersCrop/deleteParametersCrop',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/products`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersCrop = createAsyncThunk(
    'parametersCrop/createParametersCrop',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/products`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersCropById = createAsyncThunk(
    'parametersCrop/fetchParametersCropById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/products/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersCrop = createAsyncThunk(
    'parametersCrop/updateParametersCrop',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/products`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersCropSlice = createSlice({
    name: 'parametersCropSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersCrop.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCrop.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.cropParameters = payload.data;
        });
        builder.addCase(fetchParametersCrop.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersCrop.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersCrop.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersCrop.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersCrop.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersCrop.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersCrop.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersCropById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.cropParametersById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersCropById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersCropById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersCrop.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersCrop.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersCrop.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersCropSlice.reducer;

export const selectParametersCropData = (state) => state.parametersCrop.cropParameters;
export const selectParametersCropById = (state) => state.parametersCrop.cropParametersById;
export const selectParametersCropLoading = (state) => state.parametersCrop.isLoading;
export const selectParametersCropError = (state) => state.parametersCrop.error;
