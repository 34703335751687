import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    information: {},
    items: [],
    refillHistory: [],
    filters: {
        locationid: '',
    },
    error: '',
};

export const fetchFertirrigationTankInformation = createAsyncThunk(
    'fertirrigationTank/fetchFertirrigationTankInformation',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/tank/${id}`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchFertirrigationTankItems = createAsyncThunk(
    'fertirrigationTank/fetchFertirrigationTankItems',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_HOST_API_KEY}/tankstockarticle/getbytankid/${id}`,
            );

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchFertirrigationTankRefillHistory = createAsyncThunk(
    'fertirrigationTank/fetchFertirrigationTankRefillHistory',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_HOST_API_KEY}/tankrefill/getbytankid/${id}`,
            );

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const fertirrigationTankSlice = createSlice({
    name: 'fertirrigationTankSlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                locationid: '',
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchFertirrigationTankInformation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFertirrigationTankItems.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFertirrigationTankRefillHistory.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFertirrigationTankInformation.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.information = payload.data;
        });
        builder.addCase(fetchFertirrigationTankItems.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.items = payload.data;
        });
        builder.addCase(fetchFertirrigationTankRefillHistory.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.refillHistory = payload.data;
        });
        builder.addCase(fetchFertirrigationTankInformation.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchFertirrigationTankItems.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchFertirrigationTankRefillHistory.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
    },
});

export const { setFilters, resetFilters } = fertirrigationTankSlice.actions;

export default fertirrigationTankSlice.reducer;

export const selectFertirrigationTankInformation = (state) => state.fertirrigationTank.information;
export const selectFertirrigationTankItems = (state) => state.fertirrigationTank.items;
export const selectFertirrigationTankRefillHistory = (state) => state.fertirrigationTank.refillHistory;
export const selectFertirrigationTankLoading = (state) => state.fertirrigationTank.isLoading;
export const selectFertirrigationTankError = (state) => state.fertirrigationTank.error;
