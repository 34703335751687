import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import useLocales from '../../../hooks/useLocales';

import HRStaffAccessForm from '../edit/HRStaffAccessForm';

import {
  fetchHRStaffMemberAccessInfoById,
  selectHRStaffLoading,
  selectHRStaffMemberAccessInfoById
} from '../../../redux/slices/hrStaffSlice';

HRStaffManageAccessModal.propTypes = {
  passedData: PropTypes.any,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function HRStaffManageAccessModal({ passedData, open, handleClose }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const isCreate = !passedData.id;

  const accessInfo = useSelector(selectHRStaffMemberAccessInfoById);
  const isLoading = useSelector(selectHRStaffLoading);

  useEffect(() => {
    if (isCreate) return;
    dispatch(fetchHRStaffMemberAccessInfoById(passedData.id));
  }, [dispatch, isCreate, passedData.id]);

  const memberInfo = isCreate ? passedData : {
    ...passedData,
    ...accessInfo,
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="staff-access-edit"
      sx={{
        '.MuiPaper-root': {
          minWidth: { xs: '80%', sm: '60%', md: '50%', lg: '40%' },
        }
      }}
    >
      <DialogTitle id="staff-document-dialog">
        {isCreate ? translate('addAccess') : translate('manageAccess')}
      </DialogTitle>
      <DialogContent sx={{ position: 'relative' }}>
        <HRStaffAccessForm memberInfo={memberInfo} isLoading={isLoading} onClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
