import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// config
import { allLangs, defaultLang } from '../config';
// hooks
import useAuth from './useAuth';
// utils
import getErrorMessage from '../utils/getErrorMessage';
import axios from '../utils/axios';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = async (newLang) => {
    if (!newLang) return;
    i18n.changeLanguage(newLang);
    if (!user?.id) return;
    try {
      await axios.put(
        `${process.env.REACT_APP_HOST_API_KEY}/authentication/setlanguage`,
        { id: user.id, language: newLang },
      )
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      enqueueSnackbar(message, { variant: 'error', delay: 2000 });
    }
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    allLangs,
  };
}
