import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    permissions: [],
    filters: {
        roleid: 1,
        menuid: null,
    },
    error: '',
};

export const fetchAMPortalPermissions = createAsyncThunk(
    'amPortalPermissions/fetchAMPortalPermissions',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/portalpermissions`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);


export const updateAMPortalPermissions = createAsyncThunk(
    'amPortalPermissions/updateAMPortalPermissions',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/portalpermissions`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const amPortalPermissionsSlice = createSlice({
    name: 'amPortalPermissionsSlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                roleid: 1,
                menuid: null,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchAMPortalPermissions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchAMPortalPermissions.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.permissions = payload.data;
        });
        builder.addCase(fetchAMPortalPermissions.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(updateAMPortalPermissions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateAMPortalPermissions.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(updateAMPortalPermissions.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
    },
});

export const { setFilters, resetFilters } = amPortalPermissionsSlice.actions;

export default amPortalPermissionsSlice.reducer;

export const selectAMPortalPermissionsFilters = (state) => state.amPortalPermissions.filters;
export const selectAMPortalPermissionsData = (state) => state.amPortalPermissions.permissions;
export const selectAMPortalPermissionsLoading = (state) => state.amPortalPermissions.isLoading;
export const selectAMPortalPermissionsError = (state) => state.amPortalPermissions.error;
