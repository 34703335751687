import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import handleRequestError from '../../utils/handleRequestError';

const initialState = {
  isLoading: true,
  absences: [],
  workers: [],
  filters: {
    startdate: null,
    enddate: null,
    userid: null,
    companyid: null,
    supervisorid: null,
  },
  error: '',
};

export const fetchDaysOffPlanning = createAsyncThunk(
  'operationsDaysOffPlanning/fetchDaysOffPlanning',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/wfm/days-off`, {
        params
      });

      return response.data;
    } catch (err) {
      return handleRequestError(err, rejectWithValue);
    }
  }
);

export const updateDaysOffPlanning = createAsyncThunk(
  'operationsDaysOffPlanning/updateDaysOffPlanning',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/wfm/days-off-planning`, data);

      return response.data;
    } catch (err) {
      return handleRequestError(err, rejectWithValue);
    }
  }
);

const operationsDaysOffPlanningSlice = createSlice({
  name: 'operationsDaysOffPlanningSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        userid: null,
        companyid: null,
        supervisorid: null,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDaysOffPlanning.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDaysOffPlanning.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.absences = payload.data.abscenses;
      state.workers = payload.data.workers;
    });
    builder.addCase(fetchDaysOffPlanning.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateDaysOffPlanning.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateDaysOffPlanning.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(updateDaysOffPlanning.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = operationsDaysOffPlanningSlice.actions;

export default operationsDaysOffPlanningSlice.reducer;

export const selectOperationsDaysOffPlanningFilters = (state) => state.operationsDaysOffPlanning.filters;
export const selectOperationsDaysOffPlanningData = (state) => state.operationsDaysOffPlanning.absences;
export const selectOperationsDaysOffPlanningWorkers = (state) => state.operationsDaysOffPlanning.workers;
export const selectOperationsDaysOffPlanningLoading = (state) => state.operationsDaysOffPlanning.isLoading;
export const selectOperationsDaysOffPlanningError = (state) => state.operationsDaysOffPlanning.error;
