import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    absenceParameters: [],
    absenceParametersById: {},
    error: '',
};

export const fetchParametersAbsence = createAsyncThunk(
    'parametersAbsence/fetchParametersAbsence',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/wfmtype`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersAbsence = createAsyncThunk(
    'parametersAbsence/deleteParametersAbsence',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/wfmtype`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersAbsence = createAsyncThunk(
    'parametersAbsence/createParametersAbsence',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/wfmtype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersAbsenceById = createAsyncThunk(
    'parametersAbsence/fetchParametersAbsenceById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/wfmtype/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersAbsence = createAsyncThunk(
    'parametersAbsence/updateParametersAbsence',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/wfmtype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersAbsenceSlice = createSlice({
    name: 'parametersAbsenceSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersAbsence.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersAbsence.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.absenceParameters = payload.data;
        });
        builder.addCase(fetchParametersAbsence.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersAbsence.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersAbsence.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersAbsence.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersAbsence.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersAbsence.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersAbsence.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersAbsenceById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.absenceParametersById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersAbsenceById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersAbsenceById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersAbsence.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersAbsence.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersAbsence.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersAbsenceSlice.reducer;

export const selectParametersAbsenceData = (state) => state.parametersAbsence.absenceParameters;
export const selectParametersAbsenceById = (state) => state.parametersAbsence.absenceParametersById;
export const selectParametersAbsenceLoading = (state) => state.parametersAbsence.isLoading;
export const selectParametersAbsenceError = (state) => state.parametersAbsence.error;
