import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    data: [],
    staffMemberById: {},
    staffMemberDocuments: [],
    staffMemberDocument: null,
    staffMemberAccessInfoById: {},
    staffAccess: {},
    filters: {
        companyid: null,
        roleid: null,
        active: 1,
        hasaccess: 0,
    },
    error: '',
};

export const fetchHRStaff = createAsyncThunk(
    'hrStaff/fetchHRStaff',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/users`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteHRStaff = createAsyncThunk(
    'hrStaff/deleteHRStaff',
    async ({ device, data }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/users`, {
                data: {
                    ids: data,
                    device,
                },
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteHRStaffMemberAccess = createAsyncThunk(
    'hrStaff/deleteHRStaffMemberAccess',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/authentication`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const activateHRStaff = createAsyncThunk(
    'hrStaff/activateHRStaff',
    async ({ device, ids, active }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/users/inactivate`, {
                ids,
                device,
                active,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createHRStaff = createAsyncThunk(
    'hrStaff/createHRStaff',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/users`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchHRStaffMemberById = createAsyncThunk(
    'hrStaff/fetchHRStaffMemberById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/users/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateHRStaff = createAsyncThunk(
    'hrStaff/updateHRStaff',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/users`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const downloadStaffMemberDocument = createAsyncThunk(
    'hrStaff/downloadStaffMemberDocument',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/userdocument/download`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const addStaffMemberDocument = createAsyncThunk(
    'hrStaff/addStaffMemberDocument',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/userdocument`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateStaffMemberDocument = createAsyncThunk(
    'hrStaff/updateStaffMemberDocument',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/userdocument`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchHRStaffMemberDocument = createAsyncThunk(
    'hrStaff/fetchHRStaffMemberDocument',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/userdocument/${id}`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteHRStaffDocuments = createAsyncThunk(
    'hrStaff/deleteHRStaffDocuments',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/userdocument`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchHRStaffMemberAccessInfoById = createAsyncThunk(
    'hrStaff/fetchHRStaffMemberAccessInfoById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/authentication/${id}`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateHRStaffMemberAccess = createAsyncThunk(
    'hrStaff/updateHRStaffMemberAccess',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/authentication`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const addHRStaffMemberAccess = createAsyncThunk(
    'hrStaff/addHRStaffMemberAccess',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/authentication`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const resetHRStaffMemberPassword = createAsyncThunk(
    'hrStaff/resetHRStaffMemberPassword',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/authentication/change-password`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchHRStaffTemplate = createAsyncThunk(
    'hrStaff/fetchHRStaffTemplate',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/users/download_template`);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const uploadStaffMembersByFile = createAsyncThunk(
    'hrStaff/uploadStaffMembersByFile',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/users/upload_workers`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const hrStaffSlice = createSlice({
    name: 'hrStaffSlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                companyid: null,
                roleid: null,
                active: 1,
                hasaccess: 0,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchHRStaff.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHRStaff.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.data = payload.data;
        });
        builder.addCase(fetchHRStaff.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteHRStaff.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteHRStaff.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(deleteHRStaff.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteHRStaffMemberAccess.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteHRStaffMemberAccess.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(deleteHRStaffMemberAccess.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(activateHRStaff.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(activateHRStaff.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(activateHRStaff.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createHRStaff.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createHRStaff.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createHRStaff.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(addHRStaffMemberAccess.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(addHRStaffMemberAccess.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addHRStaffMemberAccess.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateHRStaffMemberAccess.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(updateHRStaffMemberAccess.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateHRStaffMemberAccess.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchHRStaffMemberById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.staffMemberById = payload.user;
            state.staffMemberDocuments = payload.documents;
            state.staffAccess = payload.access;
            state.error = '';
        });
        builder.addCase(fetchHRStaffMemberById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHRStaffMemberById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchHRStaffMemberAccessInfoById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.staffMemberAccessInfoById = payload.data
            state.error = '';
        });
        builder.addCase(fetchHRStaffMemberAccessInfoById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHRStaffMemberAccessInfoById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateHRStaff.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateHRStaff.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateHRStaff.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(downloadStaffMemberDocument.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(downloadStaffMemberDocument.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(downloadStaffMemberDocument.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(addStaffMemberDocument.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
        });
        builder.addCase(addStaffMemberDocument.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addStaffMemberDocument.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchHRStaffMemberDocument.fulfilled, (state, action) => {
            state.isLoading = false;
            state.staffMemberDocument = action.payload.data;
            state.error = '';
        });
        builder.addCase(fetchHRStaffMemberDocument.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHRStaffMemberDocument.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateStaffMemberDocument.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
        });
        builder.addCase(updateStaffMemberDocument.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateStaffMemberDocument.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteHRStaffDocuments.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(deleteHRStaffDocuments.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteHRStaffDocuments.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(resetHRStaffMemberPassword.fulfilled, (state) => {
            state.isLoading = false;
            state.filters = { ...state.filters };
            state.error = '';
        });
        builder.addCase(resetHRStaffMemberPassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(resetHRStaffMemberPassword.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchHRStaffTemplate.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(fetchHRStaffTemplate.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchHRStaffTemplate.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(uploadStaffMembersByFile.fulfilled, (state, action) => {
            if (action?.payload?.data?.inserted) {
                state.filters = { ...state.filters };
            }
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(uploadStaffMembersByFile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(uploadStaffMembersByFile.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export const { setFilters, resetFilters } = hrStaffSlice.actions;

export default hrStaffSlice.reducer;

export const selectHRStaffFilters = (state) => state.hrStaff.filters;
export const selectHRStaffData = (state) => state.hrStaff.data;
export const selectHRStaffMemberById = (state) => state.hrStaff.staffMemberById;
export const selectHRStaffMemberAccess = (state) => state.hrStaff.staffAccess;
export const selectHRStaffMemberAccessInfoById = (state) => state.hrStaff.staffMemberAccessInfoById;
export const selectHRStaffLoading = (state) => state.hrStaff.isLoading;
export const selectHRStaffMemberDocuments = (state) => state.hrStaff.staffMemberDocuments;
export const selectHRStaffMemberDocument = (state) => state.hrStaff.staffMemberDocument;
export const selectHRStaffError = (state) => state.hrStaff.error;
