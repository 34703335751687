import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    parametersWorkerFeedback: [],
    parametersWorkerFeedbackById: {},
    error: '',
};

export const fetchParametersWorkerFeedback = createAsyncThunk(
    'parametersWorkerFeedback/fetchParametersWorkerFeedback',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype?type=2`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersWorkerFeedback = createAsyncThunk(
    'parametersWorkerFeedback/deleteParametersWorkerFeedback',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersWorkerFeedback = createAsyncThunk(
    'parametersWorkerFeedback/createParametersWorkerFeedback',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersWorkerFeedbackById = createAsyncThunk(
    'parametersWorkerFeedback/fetchParametersWorkerFeedbackById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersWorkerFeedback = createAsyncThunk(
    'parametersWorkerFeedback/updateParametersWorkerFeedback',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/evaluationsubtype`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersWorkerFeedbackSlice = createSlice({
    name: 'parametersWorkerFeedbackSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersWorkerFeedback.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersWorkerFeedback.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.parametersWorkerFeedback = payload.data;
        });
        builder.addCase(fetchParametersWorkerFeedback.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersWorkerFeedback.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersWorkerFeedback.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersWorkerFeedback.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersWorkerFeedback.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersWorkerFeedback.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersWorkerFeedback.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersWorkerFeedbackById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.parametersWorkerFeedbackById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersWorkerFeedbackById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersWorkerFeedbackById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersWorkerFeedback.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersWorkerFeedback.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersWorkerFeedback.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersWorkerFeedbackSlice.reducer;

export const selectParametersWorkerFeedbackData = (state) => state.parametersWorkerFeedback.parametersWorkerFeedback;
export const selectParametersWorkerFeedbackById = (state) => state.parametersWorkerFeedback.parametersWorkerFeedbackById;
export const selectParametersWorkerFeedbackLoading = (state) => state.parametersWorkerFeedback.isLoading;
export const selectParametersWorkerFeedbackError = (state) => state.parametersWorkerFeedback.error;
