import React from 'react';
import { ModalConsumer } from '../contexts/ModalContext';
import {
  HARVEST_ENTRIES_DELETE,
  HARVEST_ENTRIES_DELETE_SINGLE,
  HARVEST_SCRAP_DELETE,
  HARVEST_SCRAP_DELETE_SINGLE,
  HARVEST_QUALITY_CONTROL_DELETE,
  HARVEST_QUALITY_CONTROL_DELETE_SINGLE,
  HARVEST_QUALITY_CONTROL_WORKERS_DELETE,
  OPERATIONS_WORK_MAPS_WORKERS_DELETE,
  OPERATIONS_WORK_MAPS_WORKERS_DELETE_SINGLE,
  OPERATIONS_WORK_HOURS_DELETE_SINGLE,
  OPERATIONS_WORK_HOURS_DELETE,
  FERTIRRIGATION_DRAINAGE_DELETE_SINGLE,
  FERTIRRIGATION_DRAINAGE_DELETE,
  FERTIRRIGATION_SUBSTRATE_DELETE_SINGLE,
  FERTIRRIGATION_SUBSTRATE_DELETE,
  FERTIRRIGATION_TANK_REFILL,
  HR_ABSENCE_BOOK_DELETE_SINGLE,
  HR_ABSENCE_BOOK_DELETE,
  HR_WORKER_FEEDBACK_DELETE,
  HR_WORKER_FEEDBACK_DELETE_SINGLE,
  HR_STAFF_DELETE,
  HR_STAFF_DELETE_SINGLE,
  HR_STAFF_CHANGE_ACTIVE_STATUS,
  HR_STAFF_CHANGE_ACTIVE_STATUS_SINGLE,
  HR_STAFF_ADD_DOCUMENT,
  HR_STAFF_EDIT_DOCUMENT,
  HR_STAFF_DOCUMENTS_DELETE,
  HR_STAFF_DOCUMENTS_DELETE_SINGLE,
  CONSUMPTION_ELECTRICITY_DELETE,
  CONSUMPTION_WATER_DELETE,
  CONSUMPTION_FUEL_DELETE,
  PARAMETERS_ABSENCE_DELETE_SINGLE,
  PARAMETERS_WORKER_FEEDBACK_DELETE_SINGLE,
  PARAMETERS_PICKING_BONUS_DELETE_SINGLE,
  PARAMETERS_DOCUMENT_TYPES_DELETE_SINGLE,
  PARAMETERS_CROP_DELETE_SINGLE,
  PARAMETERS_CROP_QUALITY_CONTROL_DELETE_SINGLE,
  PARAMETERS_CROP_CONTAINERS_DELETE_SINGLE,
  PARAMETERS_LOCATIONS_DELETE_SINGLE,
  PARAMETERS_REPORTING_DELETE_SINGLE,
  IM_STOCKS_DELETE_SINGLE,
  IM_ITEMS_DELETE_SINGLE,
  IM_ITEM_TYPES_DELETE_SINGLE,
  HR_STAFF_MANAGE_ACCESS,
  HR_STAFF_RESET_PASSWORD,
  HR_STAFF_DELETE_ACCESS,
  AM_UNSAVED_CHANGES,
  HR_STAFF_UPLOAD_WORKERS_BY_FILE,
  PARAMETERS_COMPANY_DELETE_SINGLE,
  PARAMETERS_WORK_TYPES_DELETE_SINGLE,
  IM_STOCK_ITEMS_MODAL,
  IM_STOCK_RECENT_MOVEMENTS_MODAL,
} from '../utils/const';
import { HarvestEntriesDeleteModal } from '../sections/harvest-entries/modals';
import { HarvestScrapDeleteModal } from '../sections/harvest-scrap/modals';
import { HarvestQualityControlDeleteModal, WorkersDeleteModal } from '../sections/harvest-quality-control/modals';
import { OperationsWorkHoursDeleteModal } from '../sections/operations-work-hours/modals';
import FertirrigationDrainageDeleteModal from '../sections/fertirrigation-drainage/modals/FertirrigationDrainageDeleteModal';
import FertirrigationSubstrateDeleteModal
  from '../sections/fertirrigation-substrate/modals/FertirrigationSubstrateDeleteModal';
import FertirrigationTankRefillModal
  from '../sections/fertirrigation-tank/modals/FertirrigationTankRefillModal';
import WorkMapsWorkersDeleteModal from '../sections/operations-work-maps/modals/WorkMapsWorkersDeleteModal';
import HRAbsenceBookDeleteModal from '../sections/hr-absence-book/modals/HRAbsenceBookDeleteModal';
import HRWorkerFeedbackDeleteModal from '../sections/hr-worker-feedback/modals/HRWorkerFeedbackDeleteModal';
import HRStaffChangeActiveStatusModal from '../sections/hr-staff/modals/HRStaffChangeActiveStatusModal';
import HRStaffDeleteModal from '../sections/hr-staff/modals/HRStaffDeleteModal';
import HRStaffDocumentEditModal from '../sections/hr-staff/modals/HRStaffDocumentEditModal';
import HRStaffDocumentsDeleteModal from '../sections/hr-staff/modals/HRStaffDocumentsDeleteModal';
import HRStaffManageAccessModal from '../sections/hr-staff/modals/HRStaffManageAccessModal';
import ConsumptionElectricityDeleteModal
  from '../sections/consumption-electricity/modal/ConsumptionElectricityDeleteModal';
import ConsumptionWaterDeleteModal from '../sections/consumption-water/modal/ConsumptionWaterDeleteModal';
import ConsumptionFuelDeleteModal from '../sections/consumption-fuel/modal/ConsumptionFuelDeleteModal';
import ParametersAbsenceDeleteModal from '../sections/parameters-human-resources/modal/ParametersAbsenceDeleteModal';
import ParametersWorkerFeedbackDeleteModal from '../sections/parameters-human-resources/modal/ParametersWorkerFeedbackDeleteModal';
import ParametersPickingBonusDeleteModal from '../sections/parameters-human-resources/modal/ParametersPickingBonusDeleteModal';
import ParametersDocumentTypesDeleteModal from '../sections/parameters-human-resources/modal/ParametersDocumentTypesDeleteModal';
import ParametersCropDeleteModal from '../sections/parameters-farm/modal/ParametersCropDeleteModal';
import ParametersCropQualityControlDeleteModal from '../sections/parameters-farm/modal/ParametersCropQualityControlDeleteModal';
import ParametersCropContainersDeleteModal from '../sections/parameters-farm/modal/ParametersCropContainersDeleteModal';
import ParametersLocationsDeleteModal from '../sections/parameters-farm/modal/ParametersLocationsDeleteModal';
import ParametersReportingDeleteModal from '../sections/parameters-reporting/modal/ParametersReportingDeleteModal';
import IMStocksDeleteModal from '../sections/inventory-management/modal/IMStocksDeleteModal';
import IMItemsDeleteModal from '../sections/inventory-management/modal/IMItemsDeleteModal';
import IMItemTypesDeleteModal from '../sections/inventory-management/modal/IMItemTypesDeleteModal';
import HRStaffPasswordResetModal from '../sections/hr-staff/modals/HRStaffPasswordResetModal';
import HRStaffDeleteAccessModal from '../sections/hr-staff/modals/HRStaffDeleteAccessModal';
import AMUnsavedChangesModal from '../sections/am-portal-permissions/modals/AMUnsavedChangesModal';
import HRStaffUploadWorkersByFileModal from '../sections/hr-staff/modals/HRStaffUploadWorkersByFileModal';
import ParametersCompanyDeleteModal from '../sections/parameters-human-resources/modal/ParametersCompanyDeleteModal';
import ParametersWorkTypesDeleteModal from '../sections/parameters-human-resources/modal/ParametersWorkTypesDeleteModal';
import IMStockItemsModal from '../sections/inventory-management/modal/IMStockItemsModal';
import IMStockRecentMovementsModal from '../sections/inventory-management/modal/IMStockRecentMovementsModal';

const ModalContainer = () => (
  <ModalConsumer>
    {({ handleClose, modalType, openModal, passedData }) => {
      const renderDialog = () => {
        switch (modalType) {
          case HARVEST_ENTRIES_DELETE:
            return <HarvestEntriesDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} />;
          case HARVEST_ENTRIES_DELETE_SINGLE:
            return (
              <HarvestEntriesDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} single />
            );
          case HARVEST_SCRAP_DELETE:
            return <HarvestScrapDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} />;
          case HARVEST_SCRAP_DELETE_SINGLE:
            return (
              <HarvestScrapDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} single />
            );
          case HARVEST_QUALITY_CONTROL_DELETE:
            return (
              <HarvestQualityControlDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} />
            );
          case HARVEST_QUALITY_CONTROL_DELETE_SINGLE:
            return (
              <HarvestQualityControlDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single
              />
            );
          case HARVEST_QUALITY_CONTROL_WORKERS_DELETE:
            return <WorkersDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} single />;
          case OPERATIONS_WORK_MAPS_WORKERS_DELETE:
            return <WorkMapsWorkersDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} />;
          case OPERATIONS_WORK_MAPS_WORKERS_DELETE_SINGLE:
            return (
              <WorkMapsWorkersDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} single />
            );
          case OPERATIONS_WORK_HOURS_DELETE:
            return (
              <OperationsWorkHoursDeleteModal passedData={passedData} open={openModal} handleClose={handleClose} />
            );
          case OPERATIONS_WORK_HOURS_DELETE_SINGLE:
            return (
              <OperationsWorkHoursDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single
              />
            );
          case FERTIRRIGATION_DRAINAGE_DELETE_SINGLE:
          case FERTIRRIGATION_DRAINAGE_DELETE:
            return (
              <FertirrigationDrainageDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single={modalType === FERTIRRIGATION_DRAINAGE_DELETE_SINGLE}
              />
            )
          case FERTIRRIGATION_SUBSTRATE_DELETE_SINGLE:
          case FERTIRRIGATION_SUBSTRATE_DELETE:
            return (
              <FertirrigationSubstrateDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single={modalType === FERTIRRIGATION_SUBSTRATE_DELETE_SINGLE}
              />
            )
          case FERTIRRIGATION_TANK_REFILL:
            return (
              <FertirrigationTankRefillModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case HR_ABSENCE_BOOK_DELETE:
          case HR_ABSENCE_BOOK_DELETE_SINGLE:
            return (
              <HRAbsenceBookDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single={modalType === HR_ABSENCE_BOOK_DELETE_SINGLE}
              />
            );
          case HR_WORKER_FEEDBACK_DELETE:
          case HR_WORKER_FEEDBACK_DELETE_SINGLE:
            return (
              <HRWorkerFeedbackDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single={modalType === HR_WORKER_FEEDBACK_DELETE_SINGLE}
              />
            );
          case HR_STAFF_DELETE:
          case HR_STAFF_DELETE_SINGLE:
            return (
              <HRStaffDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single={modalType === HR_STAFF_DELETE_SINGLE}
              />
            );
          case HR_STAFF_CHANGE_ACTIVE_STATUS:
          case HR_STAFF_CHANGE_ACTIVE_STATUS_SINGLE:
            return (
              <HRStaffChangeActiveStatusModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single={modalType === HR_STAFF_CHANGE_ACTIVE_STATUS_SINGLE}
              />
            )
          case HR_STAFF_ADD_DOCUMENT:
          case HR_STAFF_EDIT_DOCUMENT:
            return (
              <HRStaffDocumentEditModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                isCreate={modalType === HR_STAFF_ADD_DOCUMENT}
              />
            )
          case HR_STAFF_DOCUMENTS_DELETE_SINGLE:
          case HR_STAFF_DOCUMENTS_DELETE:
            return (
              <HRStaffDocumentsDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
                single={modalType === HR_STAFF_DOCUMENTS_DELETE_SINGLE}
              />
            )
          case HR_STAFF_MANAGE_ACCESS:
            return (
              <HRStaffManageAccessModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case CONSUMPTION_ELECTRICITY_DELETE:
            return (
              <ConsumptionElectricityDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case CONSUMPTION_WATER_DELETE:
            return (
              <ConsumptionWaterDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case CONSUMPTION_FUEL_DELETE:
            return (
              <ConsumptionFuelDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_ABSENCE_DELETE_SINGLE:
            return (
              <ParametersAbsenceDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_WORKER_FEEDBACK_DELETE_SINGLE:
            return (
              <ParametersWorkerFeedbackDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_PICKING_BONUS_DELETE_SINGLE:
            return (
              <ParametersPickingBonusDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_DOCUMENT_TYPES_DELETE_SINGLE:
            return (
              <ParametersDocumentTypesDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_CROP_DELETE_SINGLE:
            return (
              <ParametersCropDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_CROP_QUALITY_CONTROL_DELETE_SINGLE:
            return (
              <ParametersCropQualityControlDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_CROP_CONTAINERS_DELETE_SINGLE:
            return (
              <ParametersCropContainersDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_LOCATIONS_DELETE_SINGLE:
            return (
              <ParametersLocationsDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_REPORTING_DELETE_SINGLE:
            return (
              <ParametersReportingDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_COMPANY_DELETE_SINGLE:
            return (
              <ParametersCompanyDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case PARAMETERS_WORK_TYPES_DELETE_SINGLE:
            return (
              <ParametersWorkTypesDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case IM_STOCKS_DELETE_SINGLE:
            return (
              <IMStocksDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case IM_ITEMS_DELETE_SINGLE:
            return (
              <IMItemsDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case IM_ITEM_TYPES_DELETE_SINGLE:
            return (
              <IMItemTypesDeleteModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case HR_STAFF_RESET_PASSWORD:
            return (
              <HRStaffPasswordResetModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case HR_STAFF_DELETE_ACCESS:
            return (
              <HRStaffDeleteAccessModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case AM_UNSAVED_CHANGES:
            return (
              <AMUnsavedChangesModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case HR_STAFF_UPLOAD_WORKERS_BY_FILE:
            return (
              <HRStaffUploadWorkersByFileModal
                open={openModal}
                handleClose={handleClose}
              />
            )
          case IM_STOCK_ITEMS_MODAL:
            return (
              <IMStockItemsModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          case IM_STOCK_RECENT_MOVEMENTS_MODAL:
            return (
              <IMStockRecentMovementsModal
                passedData={passedData}
                open={openModal}
                handleClose={handleClose}
              />
            )
          default:
            return null;
        }
      };

      return renderDialog();
    }}
  </ModalConsumer>
);

export default ModalContainer;
