import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import computeAverage from '../../utils/computeAverage';
import { fDateUnix } from '../../utils/formatTime';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  dataSum: [],
  filters: {
    startdate: null,
    enddate: null,
    locationid: '',
  },
  error: '',
};

export const fetchFertirrigationDrainage = createAsyncThunk(
  'fertirrigationDrainage/fetchFertirrigationDrainage',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/drainage`, {
        params,
      });

      return response.data.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const deleteFertirrigationDrainage = createAsyncThunk(
  'fertirrigationDrainage/deleteFertirrigationDrainage',
  async ({ device, data }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/drainage`, {
        data: {
          ids: data,
          device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const fertirrigationDrainageSlice = createSlice({
  name: 'fertirrigationDrainageSlice',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        locationid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFertirrigationDrainage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFertirrigationDrainage.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.drainages;
      state.alerts = payload.alerts;
      state.dataSum = getDataSum(payload.drainages);
    });
    builder.addCase(deleteFertirrigationDrainage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFertirrigationDrainage.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteFertirrigationDrainage.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchFertirrigationDrainage.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setFilters, resetFilters } = fertirrigationDrainageSlice.actions;

export default fertirrigationDrainageSlice.reducer;

export const selectFertirrigationDrainageFilters = (state) => state.fertirrigationDrainage.filters;
export const selectFertirrigationDrainageData = (state) => state.fertirrigationDrainage.data;
export const selectFertirrigationDrainageSumData = (state) => state.fertirrigationDrainage.dataSum;
export const selectFertirrigationDrainageAlertData = (state) => state.fertirrigationDrainage.alerts;
export const selectFertirrigationDrainageLoading = (state) => state.fertirrigationDrainage.isLoading;
export const selectFertirrigationDrainageError = (state) => state.fertirrigationDrainage.error;

const getDataSum = (data) => {
  const updatedData = [];

  data.forEach(dataItem => {
    const existingItemIndex = updatedData.findIndex(updatedDataItem =>
      fDateUnix(updatedDataItem.date) === fDateUnix(dataItem.date) &&
      updatedDataItem.valve === dataItem.valve &&
      updatedDataItem.locationName === dataItem.locationName
    )
    if (existingItemIndex >= 0) {
      updatedData[existingItemIndex].readings.push(
        {
          updatedDt: dataItem.updatedDt,
          percentage: dataItem.percentage
        }
      )
      return
    }

    updatedData.push({
      date: dataItem.date,
      locationName: dataItem.locationName,
      valve: dataItem.valve,
      readingOneHour: null,
      readingOnePercent: null,
      readingTwoHour: null,
      readingTwoPercent: null,
      readingThreeHour: null,
      readingThreePercent: null,
      readings: [
        {
          updatedDt: dataItem.updatedDt,
          percentage: dataItem.percentage
        }
      ]
    })
  });

  const dataWithCalculatedReadings = updatedData.map((data) => {
    if (data.readings.length === 1) {
      return {
        ...data,
        readingOneHour: data.readings[0].updatedDt,
        readingOnePercent: data.readings[0].percentage,
        dailyPercent: data.readings[0].percentage
      }
    }

    const sortedReadings = data.readings.sort((a, b) => a.updatedDt - b.updatedDt).slice(0, 3);

    return {
      ...data,
      readingOneHour: sortedReadings[0]?.updatedDt || null,
      readingOnePercent: sortedReadings[0]?.percentage || null,
      readingTwoHour: sortedReadings[1]?.updatedDt || null,
      readingTwoPercent: sortedReadings[1]?.percentage || null,
      readingThreeHour: sortedReadings[2]?.updatedDt || null,
      readingThreePercent: sortedReadings[2]?.percentage || null,
      dailyPercent: computeAverage(sortedReadings.map(reading => reading.percentage)),
    }
  })

  return dataWithCalculatedReadings;
};