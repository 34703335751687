import React from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';

function LoadingBackground() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                opacity: 0.8,
                zIndex: 1,
                backgroundColor: theme.palette.background.paper,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    )
};

export default LoadingBackground;
