import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import useLocales from '../../../hooks/useLocales';

DocumentUploadInput.propTypes = {
    componentName: PropTypes.string,
    initialFileName: PropTypes.string,
    disabled: PropTypes.bool,
};

export default function DocumentUploadInput({ componentName, initialFileName = '', disabled }) {
    const { register, setValue } = useFormContext();
    const inputRef = useRef(null);
    const { translate } = useLocales();

    const [fileName, setFileName] = useState('');

    const handleChange = (e) => {
        const newLabelName = e.target.files[0]?.name;
        if (newLabelName) setFileName(newLabelName);

        setValue('file', e.target.files[0]);
    };

    return (
        <>
            <Stack direction="row" alignItems="center" sx={{ gap: '5px' }}>
                <Button
                    disabled={disabled}
                    variant="contained"
                    onClick={() => inputRef.current && inputRef.current.click()}
                >
                    {translate('upload')}
                </Button>
                {fileName || initialFileName}
            </Stack>

            <input
                {...register(componentName)}
                hidden
                type="file"
                ref={inputRef}
                onChange={(e) => handleChange(e)}
            />
        </>
    );
}