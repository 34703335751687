import React from 'react';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import DateRangePickerButton from './DateRangePickerButton';
import useDateRangePicker from './useDateRangePicker';
import Iconify from '../Iconify';
import DateRangePickerModal from './DateRangePickerModal';

import useLocales from '../../hooks/useLocales';

DateRangePicker.propTypes = {
    filterStartDate: PropTypes.instanceOf(Date),
    filterEndDate: PropTypes.instanceOf(Date),
    onFilterEndDate: PropTypes.func,
    onFilterStartDate: PropTypes.func,
    startDateViews: PropTypes.array,
    endDateViews: PropTypes.array,
}

export default function DateRangePicker({
    filterStartDate,
    filterEndDate,
    onFilterEndDate,
    onFilterStartDate,
    startDateViews,
    endDateViews,
}) {
    const { translate } = useLocales();
    const {
        startDate,
        endDate,
        onChangeStartDate,
        onChangeEndDate,
        open: openPicker,
        onOpen: onOpenPicker,
        onClose,
        isSelected: isSelectedValuePicker,
        isError,
        shortLabel,
        isRangeError,
    } = useDateRangePicker(filterStartDate, filterEndDate);
    const theme = useTheme();

    const handleChangeStartDate = (newValue) => {
        onChangeStartDate(newValue);
    };

    const handleChangeEndDate = (newValue) => {
        onChangeEndDate(newValue);
    };

    const onClosePicker = () => {
        onClose();
        onChangeStartDate(filterStartDate);
        onChangeEndDate(filterEndDate);
    };

    const onApply = () => {
        onFilterStartDate(startDate);
        onFilterEndDate(endDate);
        onClose();
    };

    return (
        <>
            <DateRangePickerButton
                isSelected={!!isSelectedValuePicker}
                startIcon={<Iconify icon="eva:calendar-fill" />}
                onClick={onOpenPicker}
                sx={{
                    maxWidth: { md: 205, xs: '100%' },
                    width: '100%',
                    border: `solid 1px ${theme.palette.divider}`,
                    padding: '15px 16px',
                    fontWeight: 500,
                    fontSize: 14,
                }}
            >
                {shortLabel}
            </DateRangePickerButton>

            <DateRangePickerModal
                variant="calendar"
                title={translate('selectDateRange')}
                startDate={startDate}
                endDate={endDate}
                onChangeStartDate={handleChangeStartDate}
                onChangeEndDate={handleChangeEndDate}
                open={openPicker}
                onClose={onClosePicker}
                isError={isError}
                onApply={onApply}
                isRangeError={isRangeError}
                startDateViews={startDateViews}
                endDateViews={endDateViews}
            />
        </>
    );
}