import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { IconButton, MenuItem, Stack } from '@mui/material';
// locales
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';

// ----------------------------------------------------------------------

LanguagePopover.propTypes = {
  title: PropTypes.string,
};

export default function LanguagePopover({ title }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleOpen} disableRipple sx={{ typography: 'body2', padding: 0, color: 'inherit' }}>
        {title &&
          <span>{title}:</span>
        }
        <Image
          disabledEffect
          src={currentLang.icon}
          alt={currentLang.label}
          sx={{ width: 24, height: 18, marginLeft: 1 }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 34, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
