import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  editedEntry: null,
  filters: {
    startdate: null,
    enddate: null,
    userid: '',
    companyid: '',
    locationid: '',
    supervisorid: '',
    registeredid: '',
    search: '',
  },
  error: '',
};

export const fetchHarvestEntries = createAsyncThunk(
  'harvestEntries/fetchEntries',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/harvests`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchHarvestEntry = createAsyncThunk('harvestEntries/fetchEntry', async (id, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/harvests/${id}`);

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

export const updateEntry = createAsyncThunk('harvestEntries/updateEntry', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/harvests`, { ...data });

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

export const deleteHarvestEntries = createAsyncThunk(
  'harvestEntries/deleteEntries',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/harvests`, {
        data: {
          ids: data.data,
          device: data.device,
        },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const harvestEntriesSlice = createSlice({
  name: 'harvestEntries',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        userid: '',
        companyid: '',
        locationid: '',
        supervisorid: '',
        registeredid: '',
        search: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHarvestEntries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestEntries.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
    });
    builder.addCase(fetchHarvestEntries.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteHarvestEntries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHarvestEntries.fulfilled, (state) => {
      state.isLoading = false;
      state.filters = { ...state.filters };
      state.error = '';
    });
    builder.addCase(deleteHarvestEntries.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchHarvestEntry.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestEntry.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.editedEntry = payload.data;
    });
    builder.addCase(fetchHarvestEntry.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = harvestEntriesSlice.actions;

export default harvestEntriesSlice.reducer;

export const selectHarvestEntriesFilters = (state) => state.harvestEntries.filters;
export const selectHarvestEntriesData = (state) => state.harvestEntries.data;
export const selectHarvestEntriesLoading = (state) => state.harvestEntries.isLoading;
export const selectHarvestEntriesError = (state) => state.harvestEntries.error;
export const selectEditedHarvestEntry = (state) => state.harvestEntries.editedEntry;
