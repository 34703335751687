// components
import { paramCase } from 'change-case';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: getIcon('ic_user'),
  harvest: getIcon('ic_harvest'),
  operations: getIcon('ic_operations'),
  fertirrigation: getIcon('ic_fertirrigation'),
  consumption: getIcon('ic_consumption'),
  humanResources: getIcon('ic_human_resources'),
  inventoryManagement: getIcon('ic_inventory_management'),
  parameters: getIcon('ic_parameters'),
  dashboard: getIcon('ic_dashboard'),
  accessManagement: getIcon('ic_access_management'),
};

export const buildConfig = (perm, translate) => {
  const config = [
    {
      subheader: 'FarmUp Portal',
      items: [{ title: translate('dashboard'), path: '/dashboard', icon: ICONS.dashboard }],
    },
  ];

  if (perm) {
    perm.forEach((route) => {
      const { module, pages } = route;

      const sect = {
        title: translate(module),
        icon: ICONS[module],
        path: paramCase(module),
        children: [],
      };

      pages.forEach((page) => {
        const moduleRoute = PATH_DASHBOARD[module];
        if (!moduleRoute) return;
        const modulePage = moduleRoute[page];
        if (!modulePage) return;
        sect.children.push({ title: translate(page), path: modulePage });
      });

      config[0].items.push(sect);
    });
  }

  return config;
};
