import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext({
  openModal: false,
  passedData: null,
});

ModalPopupProvider.propTypes = {
  children: PropTypes.node,
};

export function ModalPopupProvider({ children }) {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [passedData, setPassedData] = useState(null);

  const handleClose = () => {
    setOpenModal(false);
    setModalType('');
    setPassedData(null);
  };

  const showModal = (type, data) => {
    setOpenModal(true);
    setModalType(type);
    setPassedData(data);
  };

  const value = {
    modalType,
    passedData,
    openModal,
    handleClose,
    showModal,
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
}

export const ModalConsumer = ModalContext.Consumer;
