import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  filters: {
    date: null,
    roleid: '',
    companyid: '',
  },
  error: '',
};

export const fetchOperationsWorkingNow = createAsyncThunk(
  'operationsWorkingNow/fetchOperationsWorkingNow',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/users/daily-work-hours`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const operationsWorkingNowSlice = createSlice({
  name: 'operationsWorkingNowSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        roleid: '',
        companyid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchOperationsWorkingNow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOperationsWorkingNow.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
    });
    builder.addCase(fetchOperationsWorkingNow.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = operationsWorkingNowSlice.actions;

export default operationsWorkingNowSlice.reducer;

export const selectOperationsWorkingNowFilters = (state) => state.operationsWorkingNow.filters;
export const selectOperationsWorkingNowData = (state) => state.operationsWorkingNow.data;
export const selectOperationsWorkingNowLoading = (state) => state.operationsWorkingNow.isLoading;
export const selectOperationsWorkingNowError = (state) => state.operationsWorkingNow.error;
