import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  dataSum: [],
  filters: {
    startdate: null,
    enddate: null,
    locationid: '',
    userid: '',
  },
  error: '',
};

export const fetchHarvestPackaging = createAsyncThunk(
  'harvestPackaging/fetchHarvestPackaging',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/harvests//packing-list`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

const harvestPackagingSlice = createSlice({
  name: 'harvestPackagingSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        locationid: '',
        userid: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHarvestPackaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHarvestPackaging.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
      state.dataSum = getDataSum(payload.data);
    });
    builder.addCase(fetchHarvestPackaging.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = harvestPackagingSlice.actions;

export default harvestPackagingSlice.reducer;

export const selectHarvestPackagingFilters = (state) => state.harvestPackaging.filters;
export const selectHarvestPackagingData = (state) => state.harvestPackaging.data;
export const selectHarvestPackagingSumData = (state) => state.harvestPackaging.dataSum;
export const selectHarvestPackagingLoading = (state) => state.harvestPackaging.isLoading;
export const selectHarvestPackagingError = (state) => state.harvestPackaging.error;

// -----------------------------------------------

const getDataSum = (data) => {
  const updatedData = [];

  const obj = data.reduce((acc, curr) => {
    if (!acc[curr.workerId]) {
      acc[curr.workerId] = {
        userName: curr.userName,
        totalWorked: curr.totalWorked,
        totalPackaged: curr.totalPackaged,
      };
    } else {
      acc[curr.workerId] = {
        ...acc[curr.workerId],
        totalWorked: acc[curr.workerId].totalWorked + curr.totalWorked,
        totalPackaged: acc[curr.workerId].totalPackaged + curr.totalPackaged,
      };
    }

    return acc;
  }, {});

  Object.entries(obj).forEach((row) => {
    updatedData.push({
      workerId: row[0],
      userName: row[1].userName,
      totalWorked: row[1].totalWorked,
      totalPackaged: row[1].totalPackaged,
      packingSpeed: row[1].totalWorked ? row[1].totalPackaged / row[1].totalWorked : 0,
    });
  });

  return updatedData;
};
