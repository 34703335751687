import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    locationsParameters: [],
    locationsParametersById: {},
    error: '',
};

export const fetchParametersLocations = createAsyncThunk(
    'parametersLocations/fetchParametersLocations',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/locations`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const deleteParametersLocations = createAsyncThunk(
    'parametersLocations/deleteParametersLocations',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/locations`, {
                data,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const createParametersLocations = createAsyncThunk(
    'parametersLocations/createParametersLocations',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/locations`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const fetchParametersLocationsById = createAsyncThunk(
    'parametersLocations/fetchParametersLocationsById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/locations/${id}`);

            return response.data.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

export const updateParametersLocations = createAsyncThunk(
    'parametersLocations/updateParametersLocations',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/locations`, data);

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const ParametersLocationsSlice = createSlice({
    name: 'parametersLocationsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchParametersLocations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersLocations.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.locationsParameters = payload.data;
        });
        builder.addCase(fetchParametersLocations.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteParametersLocations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteParametersLocations.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(deleteParametersLocations.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createParametersLocations.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(createParametersLocations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createParametersLocations.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchParametersLocationsById.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.locationsParametersById = payload;
            state.error = '';
        });
        builder.addCase(fetchParametersLocationsById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchParametersLocationsById.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(updateParametersLocations.fulfilled, (state) => {
            state.isLoading = false;
            state.error = '';
        });
        builder.addCase(updateParametersLocations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateParametersLocations.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default ParametersLocationsSlice.reducer;

export const selectParametersLocationsData = (state) => state.parametersLocations.locationsParameters;
export const selectParametersLocationsById = (state) => state.parametersLocations.locationsParametersById;
export const selectParametersLocationsLoading = (state) => state.parametersLocations.isLoading;
export const selectParametersLocationsError = (state) => state.parametersLocations.error;
