import { Suspense, lazy } from 'react';
import { Navigate, useLocation, Routes, Route } from 'react-router-dom';
import { capitalCase } from 'change-case';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import Login from '../pages/auth/Login';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from './paths';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user, isInitialized, isAuthenticated } = useAuth();

  const routes = buildRoutes(user?.permissions);
  const routesCrud = buildCrudRoutes(user?.permissions);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route element={<GuestGuard />}>
        <Route path={PATH_AUTH.login} element={<Login />} />
      </Route>
      {isAuthenticated
        ? <>
          <Route element={<AuthGuard allowedRoles={[]} />}>
            <Route path="/" element={<DashboardLayout />}>
              <Route path="/" element={<Navigate to={PATH_DASHBOARD.root} replace />} />
              <Route path={PATH_DASHBOARD.root} element={<Dashboard />} />
              {routes}
              {routesCrud}
            </Route>
          </Route>

          <Route element={<LogoOnlyLayout />}>
            <Route path={PATH_PAGE.page404} element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </>
        : <Route path="*" element={<Navigate to={PATH_AUTH.login} replace />} />
      }
    </Routes>
  );
}

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const HarvestEntries = Loadable(lazy(() => import('../pages/HarvestEntries')));
const HarvestQualityControl = Loadable(lazy(() => import('../pages/HarvestQualityControl')));
const HarvestMassMovement = Loadable(lazy(() => import('../pages/HarvestMassMovement')));
const HarvestPackaging = Loadable(lazy(() => import('../pages/HarvestPackaging')));
const HarvestProductivity = Loadable(lazy(() => import('../pages/HarvestProductivity')));
const HarvestVsWaste = Loadable(lazy(() => import('../pages/HarvestVsWaste')));
const HarvestScrap = Loadable(lazy(() => import('../pages/HarvestScrap')));
const HarvestSummary = Loadable(lazy(() => import('../pages/HarvestSummary')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const OperationsChecklistReport = Loadable(lazy(() => import('../pages/OperationsChecklistReport')));
const OperationsWorkHours = Loadable(lazy(() => import('../pages/OperationsWorkHours')));
const OperationsWorkMaps = Loadable(lazy(() => import('../pages/OperationsWorkMaps')));
const OperationsWorkingNow = Loadable(lazy(() => import('../pages/OperationsWorkingNow')));
const OperationsDaysOffPlanning = Loadable(lazy(() => import('../pages/OperationsDaysOffPlanning')));
const ConsumptionElectricity = Loadable(lazy(() => import('../pages/ConsumptionElectricity')));
const ConsumptionElectricityEdit = Loadable(lazy(() => import('../pages/ConsumptionElectricityEdit')));
const ConsumptionFuel = Loadable(lazy(() => import('../pages/ConsumptionFuel')));
const ConsumptionFuelEdit = Loadable(lazy(() => import('../pages/ConsumptionFuelEdit')));
const ConsumptionWater = Loadable(lazy(() => import('../pages/ConsumptionWater')));
const ConsumptionWaterEdit = Loadable(lazy(() => import('../pages/ConsumptionWaterEdit')));
const HRAbsenceBook = Loadable(lazy(() => import('../pages/HRAbsenceBook')));
const HRAbsenceBookEdit = Loadable(lazy(() => import('../pages/HRAbsenceBookEdit')));
const HRTimeSheets = Loadable(lazy(() => import('../pages/HRTimeSheets')));
const HRPayroll = Loadable(lazy(() => import('../pages/HRPayroll')));
const HRStaff = Loadable(lazy(() => import('../pages/HRStaff')));
const HRStaffEdit = Loadable(lazy(() => import('../pages/HRStaffEdit')));
const OperationsActivityTimeReport = Loadable(lazy(() => import('../pages/OperationsActivityTimeReport')));
const HRWorkerFeedback = Loadable(lazy(() => import('../pages/HRWorkerFeedback')));
const HRWorkerFeedbackEdit = Loadable(lazy(() => import('../pages/HRWorkerFeedbackEdit')));
const IMItems = Loadable(lazy(() => import('../pages/IMItems')));
const IMItemsEdit = Loadable(lazy(() => import('../pages/IMItemsEdit')));
const IMStocks = Loadable(lazy(() => import('../pages/IMStocks')));
const IMStocksEdit = Loadable(lazy(() => import('../pages/IMStocksEdit')));
const FertirrigationDrainage = Loadable(lazy(() => import('../pages/FertirrigationDrainage')));
const FertirrigationTanks = Loadable(lazy(() => import('../pages/FertirrigationTanks')));
const FertirrigationTank = Loadable(lazy(() => import('../pages/FertirrigationTank')));
const FertirrigationSubstrate = Loadable(lazy(() => import('../pages/FertirrigationSubstrate')));
const ParametersHumanResources = Loadable(lazy(() => import('../pages/ParametersHumanResources')));
const ParametersHumanResourcesEdit = Loadable(lazy(() => import('../pages/ParametersHumanResourcesEdit')));
const ParametersReporting = Loadable(lazy(() => import('../pages/ParametersReporting')));
const ParametersReportingEdit = Loadable(lazy(() => import('../pages/ParametersReportingEdit')));
const ParametersFarm = Loadable(lazy(() => import('../pages/ParametersFarm')));
const ParametersFarmEdit = Loadable(lazy(() => import('../pages/ParametersFarmEdit')));
const HarvestEntriesEdit = Loadable(lazy(() => import('../sections/harvest-entries/edit/HarvestEntriesEdit')));
const HarvestScrapEdit = Loadable(lazy(() => import('../sections/harvest-scrap/edit-new/HarvestScrapEditNew')));
const HarvestScrapCreate = Loadable(lazy(() => import('../sections/harvest-scrap/edit-new/HarvestScrapEditNew')));
const AMPortalPermissions = Loadable(lazy(() => import('../pages/AMPortalPermissions')));
const AMLogins = Loadable(lazy(() => import('../pages/AMLogins')));
const AMLoginEdit = Loadable(lazy(() => import('../pages/AMLoginEdit')));
const OperationsWorkMapsEdit = Loadable(
  lazy(() => import('../sections/operations-work-maps/edit/OperationsWorkMapsEdit'))
);
const OperationsWorkHoursEdit = Loadable(
  lazy(() => import('../sections/operations-work-hours/edit/OperationsWorkHoursEdit'))
);
const HarvestQualityControlEdit = Loadable(
  lazy(() => import('../sections/harvest-quality-control/edit/HarvestQualityControlEdit'))
);

export const DASHBOARD_COMPONENTS = {
  [PATH_DASHBOARD.harvest.entries]: HarvestEntries,
  [PATH_DASHBOARD.harvest.qualityControl]: HarvestQualityControl,
  [PATH_DASHBOARD.harvest.massMovement]: HarvestMassMovement,
  [PATH_DASHBOARD.harvest.packaging]: HarvestPackaging,
  [PATH_DASHBOARD.harvest.productivity]: HarvestProductivity,
  [PATH_DASHBOARD.harvest.harvestVsWaste]: HarvestVsWaste,
  [PATH_DASHBOARD.harvest.scrap]: HarvestScrap,
  [PATH_DASHBOARD.harvest.summary]: HarvestSummary,
  [PATH_DASHBOARD.operations.checklistReport]: OperationsChecklistReport,
  [PATH_DASHBOARD.operations.workHours]: OperationsWorkHours,
  [PATH_DASHBOARD.operations.workMaps]: OperationsWorkMaps,
  [PATH_DASHBOARD.operations.workingNow]: OperationsWorkingNow,
  [PATH_DASHBOARD.operations.activityTimeReport]: OperationsActivityTimeReport,
  [PATH_DASHBOARD.operations.daysOffPlanning]: OperationsDaysOffPlanning,
  [PATH_DASHBOARD.consumption.electricity]: ConsumptionElectricity,
  [PATH_DASHBOARD.consumption.electricityCreate]: ConsumptionElectricityEdit,
  [PATH_DASHBOARD.consumption.electricityEdit]: ConsumptionElectricityEdit,
  [PATH_DASHBOARD.consumption.fuel]: ConsumptionFuel,
  [PATH_DASHBOARD.consumption.fuelCreate]: ConsumptionFuelEdit,
  [PATH_DASHBOARD.consumption.fuelEdit]: ConsumptionFuelEdit,
  [PATH_DASHBOARD.consumption.water]: ConsumptionWater,
  [PATH_DASHBOARD.consumption.waterCreate]: ConsumptionWaterEdit,
  [PATH_DASHBOARD.consumption.waterEdit]: ConsumptionWaterEdit,
  [PATH_DASHBOARD.humanResources.absenceBook]: HRAbsenceBook,
  [PATH_DASHBOARD.humanResources.absenceBookEdit]: HRAbsenceBookEdit,
  [PATH_DASHBOARD.humanResources.absenceBookCreate]: HRAbsenceBookEdit,
  [PATH_DASHBOARD.humanResources.timesheets]: HRTimeSheets,
  [PATH_DASHBOARD.humanResources.payroll]: HRPayroll,
  [PATH_DASHBOARD.humanResources.staff]: HRStaff,
  [PATH_DASHBOARD.humanResources.staffEdit]: HRStaffEdit,
  [PATH_DASHBOARD.humanResources.staffCreate]: HRStaffEdit,
  [PATH_DASHBOARD.humanResources.workerFeedback]: HRWorkerFeedback,
  [PATH_DASHBOARD.humanResources.workerFeedbackEdit]: HRWorkerFeedbackEdit,
  [PATH_DASHBOARD.humanResources.workerFeedbackCreate]: HRWorkerFeedbackEdit,
  [PATH_DASHBOARD.inventoryManagement.items]: IMItems,
  [PATH_DASHBOARD.inventoryManagement.itemsEdit]: IMItemsEdit,
  [PATH_DASHBOARD.inventoryManagement.itemsCreate]: IMItemsEdit,
  [PATH_DASHBOARD.inventoryManagement.stocks]: IMStocks,
  [PATH_DASHBOARD.inventoryManagement.stocksCreate]: IMStocksEdit,
  [PATH_DASHBOARD.inventoryManagement.stocksEdit]: IMStocksEdit,
  [PATH_DASHBOARD.fertirrigation.substrate]: FertirrigationSubstrate,
  [PATH_DASHBOARD.fertirrigation.drainage]: FertirrigationDrainage,
  [PATH_DASHBOARD.fertirrigation.tanks]: FertirrigationTanks,
  [PATH_DASHBOARD.fertirrigation.tanksEdit]: FertirrigationTank,
  [PATH_DASHBOARD.parameters.humanResources]: ParametersHumanResources,
  [PATH_DASHBOARD.parameters.humanResourcesEdit]: ParametersHumanResourcesEdit,
  [PATH_DASHBOARD.parameters.humanResourcesCreate]: ParametersHumanResourcesEdit,
  [PATH_DASHBOARD.parameters.farm]: ParametersFarm,
  [PATH_DASHBOARD.parameters.farmEdit]: ParametersFarmEdit,
  [PATH_DASHBOARD.parameters.farmCreate]: ParametersFarmEdit,
  [PATH_DASHBOARD.parameters.reporting]: ParametersReporting,
  [PATH_DASHBOARD.parameters.reportingCreate]: ParametersReportingEdit,
  [PATH_DASHBOARD.parameters.reportingEdit]: ParametersReportingEdit,
  [PATH_DASHBOARD.harvest.entriesEdit]: HarvestEntriesEdit,
  [PATH_DASHBOARD.harvest.scrapEdit]: HarvestScrapEdit,
  [PATH_DASHBOARD.harvest.scrapCreate]: HarvestScrapCreate,
  [PATH_DASHBOARD.harvest.qualityControlEdit]: HarvestQualityControlEdit,
  [PATH_DASHBOARD.operations.workMapsEdit]: OperationsWorkMapsEdit,
  [PATH_DASHBOARD.operations.workHoursEdit]: OperationsWorkHoursEdit,
  [PATH_DASHBOARD.accessManagement.permissions]: AMPortalPermissions,
  [PATH_DASHBOARD.accessManagement.access]: AMLogins,
  [PATH_DASHBOARD.accessManagement.accessEdit]: AMLoginEdit,
  [PATH_DASHBOARD.accessManagement.accessCreate]: AMLoginEdit,
};

const buildRoutes = (perm) => {
  const routes = [];

  if (perm) {
    perm.forEach((route) => {
      const { module, pages } = route;

      pages?.forEach((page) => {
        const moduleRoute = PATH_DASHBOARD[module];
        if (!moduleRoute) return;
        const modulePage = moduleRoute[page];
        if (!modulePage) return;
        const Component = DASHBOARD_COMPONENTS[modulePage];
        if (!Component) return;
        routes.push(
          <Route key={PATH_DASHBOARD[module][page]} path={PATH_DASHBOARD[module][page]} element={<Component />} />
        );
      });
    });
  }

  return routes;
};

const buildCrudRoutes = (perm) => {
  const routes = [];

  if (perm) {
    perm.forEach((route) => {
      const { module, crud, pages } = route;

      pages?.forEach((page) => {
        const arr = ['edit', 'create'];

        arr.forEach((item) => {
          const moduleRoute = PATH_DASHBOARD[module];
          if (!moduleRoute) return;
          const modulePage = moduleRoute[`${page}${capitalCase(item)}`]
          if (!modulePage) return;
          const Component = DASHBOARD_COMPONENTS[modulePage];
          if (!Component) return;
          if (Component && crud[item]) {
            routes.push(
              <Route
                key={PATH_DASHBOARD[module][page]}
                path={PATH_DASHBOARD[module][`${page}${capitalCase(item)}`]}
                element={<Component />}
              />
            );
          }
        });
      });
    });
  }

  return routes;
};
