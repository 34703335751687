import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

import useLocales from '../../../hooks/useLocales';

import { FormProvider, RHFDatePicker, RHFSelect, RHFTextField } from '../../../components/hook-form';
import ErrorMessageBox from '../../../components/ErrorMessageBox';
import DocumentUploadInput from '../edit/DocumentUploadInput';

import { fDateTimeSecondsUTC } from '../../../utils/formatTime';
import { dateToRequestFormat } from '../../../utils/misc';

import {
    addStaffMemberDocument,
    fetchHRStaffMemberDocument,
    selectHRStaffMemberDocument,
    updateStaffMemberDocument,
    selectHRStaffLoading,
} from '../../../redux/slices/hrStaffSlice';
import { fetchHRStaffDocuments, selectHRStaffDocumentsFilters } from '../../../redux/slices/hrStaffDocumentsSlice';
import {
    fetchDocumentTypes,
    fetchWorkers,
    selectDocumentTypes,
    selectWorkers,
} from '../../../redux/slices/selectorsSlice';

import { PATH_DASHBOARD } from '../../../routes/paths';
import formatFileToBase64 from '../../../utils/formatFileToBase64';

HRStaffDocumentEditModal.propTypes = {
    passedData: PropTypes.any,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    isCreate: PropTypes.bool,
};

const linkValidationRegex =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export default function HRStaffDocumentEditModal({ passedData, open, handleClose, isCreate }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const { translate } = useLocales();
    const documentTypes = useSelector(selectDocumentTypes);
    const { enqueueSnackbar } = useSnackbar();
    const staffMemberDocument = useSelector(selectHRStaffMemberDocument);
    const workersList = useSelector(selectWorkers);
    const filters = useSelector(selectHRStaffDocumentsFilters);
    const [error, setError] = useState('');
    const isLoading = useSelector(selectHRStaffLoading);

    const isAllDocumentsPage = location.pathname === PATH_DASHBOARD.humanResources.staff;

    const onSubmit = async (data) => {
        const documentFile = data.file;
        if (documentFile && documentFile.size > 10 * 1024 * 1024) {
            setError(translate('fileTooBig'));
            return;
        }

        const formData = {
            expirationDate: data.expirationDate ? dateToRequestFormat(data.expirationDate) : null,
            file: documentFile ? await formatFileToBase64(documentFile) : null,
            filename: documentFile ? documentFile.name : data.filename,
            userId: passedData?.userId || data.userId,
            externalLink: data.externalLink || null,
            notes: data.notes || null,
            documentTypeId: data.documentTypeId,
        };

        if (!isCreate) formData.id = Number(passedData.id);

        const request = isCreate ? addStaffMemberDocument : updateStaffMemberDocument;
        setError('');

        dispatch(request(formData)).then((res) => {
            if (res.error) {
                setError(res.payload);
                return;
            }
            if (isAllDocumentsPage) {
                dispatch(fetchHRStaffDocuments({ ...filters }));
            }
            enqueueSnackbar(translate('update_successful'), { delay: 2000 });
            handleClose();
        });
    };

    const documentEditSchema = Yup.object().shape({
        documentTypeId: Yup.number().required(translate('fieldIsRequired')),
        file: Yup.mixed(),
        externalLink: Yup
            .string()
            .max(100, translate('linkIsTooLong'))
            .when({
                is: val => val,
                then: Yup.string().matches(
                    linkValidationRegex,
                    translate('invalidLinkFormat')
                ),
                otherwise: Yup.string().notRequired(),
            }),
        notes: Yup.string(),
        expirationDate: Yup.string(),
        filename: Yup.string(),
        createdAt: Yup.string(),
        updatedAt: Yup.string(),
        userName: Yup.string(),
        userId: Yup.number(),
    });

    const defaultValues = useMemo(
        () => {
            if (isCreate) {
                return {
                    documentTypeId: passedData?.documentTypeId || '',
                    userId: passedData?.userId || '',
                    externalLink: '',
                    file: null,
                    notes: '',
                    expirationDate: '',
                    filename: '',
                    createdAt: '',
                    updatedAt: '',
                    userName: '',
                }
            }
            return {
                userId: staffMemberDocument?.userId || '',
                documentTypeId: passedData?.documentTypeId || '',
                externalLink: staffMemberDocument?.externalLink || '',
                notes: staffMemberDocument?.notes || '',
                expirationDate: staffMemberDocument?.expirationDate
                    ? new Date(staffMemberDocument.expirationDate)
                    : '',
                filename: staffMemberDocument?.filename || '',
                userName: staffMemberDocument?.userName || '',
                updatedAt: staffMemberDocument?.updatedAt ? fDateTimeSecondsUTC(staffMemberDocument.updatedAt) : '',
                createdAt: staffMemberDocument?.createdAt ? fDateTimeSecondsUTC(staffMemberDocument.createdAt) : '',
                file: null,
            }
        },
        [passedData, staffMemberDocument, isCreate]
    );

    const methods = useForm({
        resolver: yupResolver(documentEditSchema),
        defaultValues,
    });

    const isFormDisabled =
        isLoading ||
        (!isCreate && !staffMemberDocument) ||
        !documentTypes ||
        (isCreate && !workersList) ||
        methods.formState.isSubmitting;

    useEffect(() => {
        if (!isCreate && !staffMemberDocument) return;
        methods.reset(defaultValues);
    }, [defaultValues, isCreate, methods, staffMemberDocument]);

    useEffect(() => {
        if (!isCreate) {
            dispatch(fetchHRStaffMemberDocument(passedData.id));
        }
        if (isAllDocumentsPage) {
            dispatch(fetchWorkers());
        }
        dispatch(fetchDocumentTypes());
    }, [dispatch, isAllDocumentsPage, isCreate, passedData?.id]);

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="staff-document-dialog"
            sx={{
                '.MuiPaper-root': {
                    minWidth: { xs: '80%', sm: '60%', md: '50%', lg: '40%' },
                }
            }}
        >
            <DialogTitle id="staff-document-dialog">
                {isCreate ? translate('addDocument') : translate('editDocument')}
            </DialogTitle>
            <DialogContent>
                <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            paddingBottom: 2,
                            paddingTop: 1,
                        }}
                    >
                        {isAllDocumentsPage &&
                            <RHFSelect disabled={isFormDisabled} name="userId" label={translate('worker')}>
                                {workersList?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.workerfullname}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        }
                        <RHFSelect disabled={isFormDisabled} name="documentTypeId" label={translate('documentType')}>
                            {documentTypes?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <DocumentUploadInput
                            disabled={isFormDisabled}
                            componentName="file"
                            initialFileName={isCreate ? '' : staffMemberDocument?.filename}
                        />
                        <RHFTextField disabled={isFormDisabled} name="externalLink" label={translate('link')} />
                        <RHFDatePicker disabled={isFormDisabled} name="expirationDate" label={translate('expiryDate')} />
                        <RHFTextField disabled={isFormDisabled} name="notes" label={translate('notes')} />
                        {!isCreate && (
                            <>
                                <RHFTextField disabled name="createdAt" label={translate('createdAt')} />
                                <RHFTextField disabled name="updatedAt" label={translate('updatedAt')} />
                                <RHFTextField disabled name="userName" label={translate('updatedBy')} />
                            </>
                        )}
                    </Box>
                    <ErrorMessageBox error={error} />
                    <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Button disabled={isFormDisabled} variant="outlined" color="primary" autoFocus onClick={handleClose}>
                            {translate('cancel')}
                        </Button>
                        <LoadingButton type="submit" variant="contained" loading={isFormDisabled}>
                            {translate('save')}
                        </LoadingButton>
                    </Stack>
                </FormProvider>
            </DialogContent>
        </Dialog>
    );
}

