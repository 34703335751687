import * as Yup from 'yup';
import React, { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, IconButton, InputAdornment, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// utils
import getErrorMessage from '../../../utils/getErrorMessage';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(translate('loginIsRequired')),
    password: Yup.string().required(translate('passwordIsRequired')),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.username, data.password);
    } catch (error) {
      console.error(error);

      if (isMountedRef.current) {
        const code = error?.errors ? error.errors[0]?.code : null;
        const message = getErrorMessage(code);
        setError('afterSubmit', { ...error, message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="username" label={translate('login')} />

        <RHFTextField
          name="password"
          label={translate('password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" sx={{ mt: 3 }} variant="contained" loading={isSubmitting}>
        {translate('login')}
      </LoadingButton>
      <Stack display="flex" flexDirection="row" p={2} fontSize={11} justifyContent="center">
        <Link paddingX={1} href="https://help.myfarmup.com/portal">{translate('university')}</Link>
        <Link paddingX={1} href="https://farmup.pt/">{translate('website')}</Link>
        <Link paddingX={1} href="mailto:info@farmup.pt">{translate('email')}</Link>
        <Link paddingX={1} href="https://wa.me/15128279220">{translate('whatsApp')}</Link>
        <Link paddingX={1} href="tel:351913119461">{translate('phoneContact')}</Link>
      </Stack>
    </FormProvider>
  );
}
