export const getStartDate = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);

  return date;
};

export const numberByLocale = (number, locale, minimumFractionDigits = 0, maximumFractionDigits= 2 ) =>
  Intl.NumberFormat(locale, { minimumFractionDigits, maximumFractionDigits }).format(number);

export const roundToTwoDecimals = (number) => Math.round(number * 100) / 100;

export function applySortFilter({ tableData, comparator, searchString = null }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (searchString?.length > 1) {
    tableData = tableData.filter((item) =>
      Object.keys(item).some((i) => {
        if (!item[i]) return false
        return item[i].toString().toLowerCase().indexOf(searchString.toLowerCase()) !== -1
      })
    );
  }

  return tableData;
}

export function dateToRequestFormat(date) {
  const dateCopy = new Date(date);
  dateCopy.setHours(0);
  dateCopy.setMinutes(0);
  dateCopy.setSeconds(0);
  dateCopy.setMilliseconds(0);

  return dateCopy.getTime() - 60000 * dateCopy.getTimezoneOffset(); // Convert to UTC time
};
