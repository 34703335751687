import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import ErrorMessageBox from '../../../components/ErrorMessageBox';
import { RHFTextField, RHFSelect, RHFSwitch, FormProvider } from '../../../components/hook-form';
import LoadingBackground from '../../../components/LoadingBackground';

import { HR_STAFF_DELETE_ACCESS, HR_STAFF_RESET_PASSWORD } from '../../../utils/const';
import transformEmptyStringToUndefined from '../../../utils/transformEmptyStringToUndefined';

import useLocales from '../../../hooks/useLocales';
import useModals from '../../../hooks/useModals';

import { addHRStaffMemberAccess, updateHRStaffMemberAccess } from '../../../redux/slices/hrStaffSlice';
import { fetchRoles, fetchWorkers, selectRoles, selectWorkers } from '../../../redux/slices/selectorsSlice';

export const passwordValidationRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;

const ACCESS_TYPES = [
    { value: 1, label: 'portal' },
    { value: 2, label: 'app' },
    { value: 3, label: 'both' },
];

const LANGUAGES = [
    { value: 'pt', label: 'portuguese' },
    { value: 'en', label: 'english' },
    { value: 'es', label: 'spanish' },
];

HRStaffAccessForm.propTypes = {
    memberInfo: PropTypes.object,
    showWorker: PropTypes.bool,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default function HRStaffAccessForm({ memberInfo, onClose, isLoading, showWorker }) {
    const dispatch = useDispatch();
    const { translate } = useLocales();
    const [error, setError] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { showModal } = useModals();
    const workersList = useSelector(selectWorkers);
    const rolesList = useSelector(selectRoles);

    const isCreate = !memberInfo.id;
    const onSubmit = async (data) => {
        const formData = {
            username: data.username,
            accessType: data.accessType,
            userId: memberInfo.userId || data.userId,
            roleId: data.roleId || memberInfo.roleId,
        };

        if (isCreate) {
            formData.language = data.language;
            formData.password = data.password;
        } else {
            formData.active = data.active ? 1 : 0;
            formData.id = Number(memberInfo.id);
        }

        const request = isCreate ? addHRStaffMemberAccess : updateHRStaffMemberAccess;
        setError('');

        dispatch(request(formData)).then((res) => {
            if (res.error) {
                setError(res.payload);
                return;
            }
            enqueueSnackbar(translate('update_successful'), { delay: 2000 });
            if (onClose) onClose();
        });
    };

    const accessSchema = Yup.object().shape({
        username: Yup
            .string()
            .transform(transformEmptyStringToUndefined)
            .max(100, translate('usernameIsTooLong'))
            .min(8, translate('usernameIsTooShort'))
            .required(translate('fieldIsRequired')),
        password: Yup
            .string()
            .transform(transformEmptyStringToUndefined)
            .max(100, translate('passwordIsTooLong'))
            .min(6, translate('passwordIsTooShort'))
            .when({
                is: val => val && isCreate,
                then: Yup.string().matches(
                    passwordValidationRegex,
                    translate('invalidPasswordFormat')
                ),
                otherwise: Yup.string().notRequired(),
            })
            .required(translate('fieldIsRequired')),
        accessType: Yup.number()
            .transform(transformEmptyStringToUndefined)
            .typeError(translate('theValueShouldBeANumber'))
            .required(translate('fieldIsRequired')),
        language: isCreate ? Yup.string().required(translate('fieldIsRequired')) : Yup.string(),
        active: Yup.bool(),
        userId: Yup.number().transform(transformEmptyStringToUndefined).required(translate('fieldIsRequired')),
        roleId: Yup.number().transform(transformEmptyStringToUndefined).required(translate('fieldIsRequired')),
    });

    const defaultValues = useMemo(
        () => {
            if (isCreate) {
                return {
                    username: '',
                    password: '',
                    accessType: '',
                    language: '',
                    userId: '',
                    roleId: '',
                    active: false,
                }
            }
            return {
                username: memberInfo.username || '',
                password: '* * * * *',
                accessType: memberInfo.accessType || '',
                userId: memberInfo.userId || '',
                roleId: memberInfo.roleId || '',
                active: !!memberInfo.active,
            }
        },
        [isCreate, memberInfo.username, memberInfo.accessType, memberInfo.userId, memberInfo.roleId, memberInfo.active]
    );


    const methods = useForm({
        resolver: yupResolver(accessSchema),
        defaultValues,
    });

    const isFormDisabled =
        (!isCreate && !memberInfo) ||
        methods.formState.isSubmitting;

    useEffect(() => {
        if (memberInfo.userId) return;
        dispatch(fetchWorkers());
        dispatch(fetchRoles());
    }, [dispatch, memberInfo.userId])

    useEffect(() => {
        methods.reset(defaultValues);
    }, [defaultValues, methods]);

    return (
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
            {isLoading && <LoadingBackground />}
            <Box
                sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    paddingBottom: 2,
                    paddingTop: 1,
                }}
            >
                {showWorker && <>
                    <RHFSelect
                        disabled={isFormDisabled || !!memberInfo.userId}
                        name="userId"
                        label={translate('worker')}
                    >
                        {workersList?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {translate(item.workerfullname)}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <RHFSelect
                        disabled={isFormDisabled}
                        name="roleId"
                        label={translate('role')}
                    >
                        {rolesList?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {translate(item.name)}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                </>
                }
                <RHFTextField disabled={isFormDisabled} name="username" label={translate('userName')} />
                <RHFTextField
                    disabled={!isCreate || isFormDisabled}
                    name="password"
                    label={translate('password')}
                    helperText={!methods.formState.errors.password
                        ? translate('passwordHint')
                        : methods.formState.errors.password.message
                    }
                />
                <RHFSelect disabled={isFormDisabled} name="accessType" label={translate('access')}>
                    {ACCESS_TYPES?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {translate(item.label)}
                        </MenuItem>
                    ))}
                </RHFSelect>
                {isCreate &&
                    <RHFSelect disabled={isFormDisabled} name="language" label={translate('onboardingLanguage')}>
                        {LANGUAGES?.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                                {translate(item.label)}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                }
                {!isCreate && <RHFSwitch name="active" label={translate('active')} />}
            </Box>
            <ErrorMessageBox error={error} />
            <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 3 }}>
                {!isCreate && <>
                    <LoadingButton
                        onClick={() => showModal(HR_STAFF_DELETE_ACCESS, memberInfo.id)}
                        variant="contained"
                        color="error"
                        loading={isFormDisabled}
                    >
                        {translate('deleteAccess')}
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => showModal(HR_STAFF_RESET_PASSWORD, memberInfo.id)}
                        variant="contained"
                        color="error"
                        loading={isFormDisabled}
                    >
                        {translate('resetPassword')}
                    </LoadingButton>
                </>
                }
                {onClose &&
                    <Button
                        disabled={isFormDisabled}
                        variant="outlined"
                        color="primary"
                        autoFocus
                        onClick={onClose}
                    >
                        {translate('cancel')}
                    </Button>
                }
                <LoadingButton type="submit" variant="contained" loading={isFormDisabled}>
                    {translate('save')}
                </LoadingButton>
            </Stack>
        </FormProvider>
    )
}
