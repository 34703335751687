import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
    isLoading: true,
    imStockMovements: [],
    filters: {
        startdate: null,
        enddate: null,
        operationtypeid: null,
        stockid: null,
        articleid: null,
        articletypeid: null,
    },
    error: '',
};

export const fetchIMStockMovements = createAsyncThunk(
    'imStockMovements/fetchIMStockMovements',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/operationHistory`, {
                params,
            });

            return response.data;
        } catch (err) {
            const code = err?.errors ? err.errors[0]?.code : null;
            const message = getErrorMessage(code);
            return rejectWithValue(message);
        }
    }
);

const imStockMovementsSlice = createSlice({
    name: 'imStockMovementsSlice',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters(state) {
            state.filters = {
                ...state.filters,
                operationtypeid: null,
                stockid: null,
                articleid: null,
                articletypeid: null,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchIMStockMovements.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchIMStockMovements.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.error = '';
            state.imStockMovements = payload.data;
        });
        builder.addCase(fetchIMStockMovements.rejected, (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        });
    },
});

export const { setFilters, resetFilters } = imStockMovementsSlice.actions;

export default imStockMovementsSlice.reducer;

export const selectIMStockMovementsData = (state) => state.imStockMovements.imStockMovements;
export const selectIMStockMovementsLoading = (state) => state.imStockMovements.isLoading;
export const selectIMStockMovementsError = (state) => state.imStockMovements.error;
export const selectIMStockMovementsFilters = (state) => state.imStockMovements.filters;
