import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import getErrorMessage from '../../utils/getErrorMessage';

const initialState = {
  isLoading: true,
  data: [],
  editedWorkMaps: null,
  workersData: [],
  filters: {
    startdate: null,
    enddate: null,
    supervisorid: '',
    locationid: '',
    worktypeid: '',
    unclosed: '',
    search: '',
  },
  error: '',
};

export const fetchWorkMaps = createAsyncThunk(
  'operationsWorkMaps/fetchWorkMaps',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmap`, {
        params: { ...params },
      });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const fetchWorkers = createAsyncThunk('operationsWorkMaps/fetchWorkers', async (id, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers/getbyworkingmap/${id}`);

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

export const fetchWorkMapsSingle = createAsyncThunk(
  'operationsWorkMaps/fetchWorkMapsSingle',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/workingmap/${id}`);

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const updateWorkMapsSingle = createAsyncThunk(
  'operationsWorkMaps/updateWorkMapsSingle',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_HOST_API_KEY}/workingmap`, { ...data });

      return response.data;
    } catch (err) {
      const code = err?.errors ? err.errors[0]?.code : null;
      const message = getErrorMessage(code);
      return rejectWithValue(message);
    }
  }
);

export const deleteWorker = createAsyncThunk('operationsWorkMaps/deleteWorker', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_HOST_API_KEY}/workingmapworkers`, {
      data: {
        ids: data.data,
        device: data.device,
      },
    });

    return response.data;
  } catch (err) {
    const code = err?.errors ? err.errors[0]?.code : null;
    const message = getErrorMessage(code);
    return rejectWithValue(message);
  }
});

const operationsWorkMapsSlice = createSlice({
  name: 'operationsWorkMaps',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = {
        ...state.filters,
        supervisorid: '',
        locationid: '',
        worktypeid: '',
        unclosed: '',
        search: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWorkMaps.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkMaps.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.data = payload.data;
    });
    builder.addCase(fetchWorkMaps.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchWorkMapsSingle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkMapsSingle.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.editedWorkMaps = payload.data;
    });
    builder.addCase(fetchWorkMapsSingle.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchWorkers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWorkers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = '';
      state.workersData = payload.data;
    });
    builder.addCase(fetchWorkers.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteWorker.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteWorker.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
    });
    builder.addCase(deleteWorker.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setData, setFilters, resetFilters } = operationsWorkMapsSlice.actions;

export default operationsWorkMapsSlice.reducer;

export const selectOperationsWorkMapsFilters = (state) => state.operationsWorkMaps.filters;
export const selectOperationsWorkMapsData = (state) => state.operationsWorkMaps.data;
export const selectOperationsWorkMapsLoading = (state) => state.operationsWorkMaps.isLoading;
export const selectOperationsWorkMapsError = (state) => state.operationsWorkMaps.error;
export const selectOperationsEditedWorkMaps = (state) => state.operationsWorkMaps.editedWorkMaps;
export const selectOperationsWorkMapsWorkers = (state) => state.operationsWorkMaps.workersData;
