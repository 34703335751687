import { useState } from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButtonAnimate } from '../../../components/animate';
import useLocales from '../../../hooks/useLocales';
import Iconify from '../../../components/Iconify';

// -------------------

const InputStyle = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: theme.spacing(5),
    '&.Mui-focused': {
      boxShadow: theme.customShadows.z8,
    },
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// --------------------

function SearchInKb() {
  const { currentLang, translate } = useLocales();
  const [search, setSearch] = useState('');

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchKb = (e) => {
    e.preventDefault();
    window.open(`https://help.myfarmup.com/portal/${currentLang.value}/kb/search/${search}`, '_blank');
    setSearch('');
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <InputStyle
        placeholder={translate('searchInHelp')}
        value={search}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButtonAnimate type="submit" onClick={handleSearchKb}>
                <Iconify icon="bi:search" width={16} height={16} />
              </IconButtonAnimate>
            </InputAdornment>
          ),
        }}
        sx={{ my: 5, '& .MuiOutlinedInput-root': { pr: 0.5 } }}
      />
    </Box>
  );
}

export default SearchInKb;
